import React, { useState, useEffect } from 'react'

import { useCheckout } from '@hooks/useCheckout'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _merge from 'lodash/merge'

AdditionalMaps.propTypes = {
  manualInitialAddress: PropTypes.object,
  startEmpty: PropTypes.bool,
  outline: PropTypes.bool
}

export default function AdditionalMaps ({ manualInitialAddress = {}, startEmpty = false, outline = false }) {
  const [checkout, setCheckout] = useCheckout()
  const { address: initialAddress } = checkout
  const initialAdditionalInformation = _get(initialAddress, 'additional_information', '')
  const [additionalInformation, setAdditionalInformation] = useState(startEmpty ? '' : _get(manualInitialAddress, 'additional_information', initialAdditionalInformation))

  useEffect(() => {
    if (_get(checkout, 'address.additional_information', false) !== additionalInformation) {
      setCheckout({
        field: 'address',
        value: _merge(
          {},
          _get(checkout, 'address', {}),
          { additional_information: additionalInformation }
        )
      })
      setCheckout({
        field: 'additional_information',
        value: additionalInformation
      })
    }
    // eslint-disable-next-line
  }, [additionalInformation])

  return (
    <input
      className={outline ? 'form-control form-control--outline' : 'form-control'}
      name="additionalInformation"
      placeholder="705, Casa A, etc"
      type="text"
      value={additionalInformation}
      onChange={(e) => setAdditionalInformation(e.target.value)}
    />
  )
}
