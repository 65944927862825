import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/giftcards/:action?/:giftcardId?',
  guard: true,
  layout: AdminLayout,
  navs: [
    { name: 'Pedidos', path: '/admin/giftcards/orders/:giftcardId' },
    { name: 'Editar', path: '/admin/giftcards/edit/:giftcardId' }
  ],
  excludedPaths: [
    '/admin/giftcards',
    '/admin/giftcards/',
    '/admin/giftcards/create',
    '/admin/giftcards/reports'
  ],
  routes: [
    {
      exact: true,
      path: '/admin/giftcards',
      component: lazy(() => retry(() => import('@pages/Admin/Giftcards/List')))
    },
    {
      exact: true,
      path: '/admin/giftcards/reports',
      component: lazy(() => retry(() => import('@pages/Admin/Giftcards/Reports')))
    },
    {
      exact: true,
      path: '/admin/giftcards/create',
      component: lazy(() => retry(() => import('@pages/Admin/Giftcards/Create')))
    },
    {
      exact: true,
      path: '/admin/giftcards/orders/:giftcardId',
      component: lazy(() => retry(() => import('@pages/Admin/Giftcards/Orders')))
    },
    {
      exact: false,
      path: '/admin/giftcards/edit/:giftcardId',
      component: lazy(() => retry(() => import('@pages/Admin/Giftcards/Edit')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
