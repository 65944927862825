import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as SiteLayout } from '@pages/Site/Layout'
import retry from '@utils/retry'
export default {
  path: '*',
  layout: SiteLayout,
  settings: false,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => retry(() => import('@pages/Site/HomePage')))
    },
    {
      exact: true,
      path: '/mailtrackid/:token',
      component: lazy(() => retry(() => import('@pages/Site/HomePage')))
    },
    {
      exact: true,
      guard: true,
      path: '/settings/pedidos',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Orders')))
    },
    {
      exact: true,
      guard: true,
      path: '/settings/reservas',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Reservations')))
    },
    {
      exact: true,
      path: '/pedir/:categoryName?',
      component: lazy(() => retry(() => import('@pages/Site/Order')))
    },
    {
      exact: true,
      path: '/pedir/:categoryName?/mailtrackid/:token',
      component: lazy(() => retry(() => import('@pages/Site/Order')))
    },
    {
      exact: true,
      path: '/pedir/mailtrackid/:token',
      component: lazy(() => retry(() => import('@pages/Site/Order')))
    },
    {
      exact: true,
      path: '/record/:recordName?/:categoryName?',
      component: lazy(() => retry(() => import('@pages/Site/Order')))
    },
    {
      exact: true,
      path: '/checkout',
      component: lazy(() => retry(() => import('@pages/Site/Checkout')))
    },
    {
      exact: true,
      path: '/track/:trackId',
      component: lazy(() => retry(() => import('@pages/Site/Track')))
    },
    {
      exact: true,
      path: '/review/:orderId',
      component: lazy(() => retry(() => import('@pages/Site/Review')))
    },
    {
      exact: true,
      path: '/reservar',
      component: lazy(() => retry(() => import('@pages/Site/Reserve')))
    },
    {
      exact: true,
      path: '/reservar/exito',
      component: lazy(() => retry(() => import('@pages/Site/Reserve/Success')))
    },
    {
      exact: true,
      path: '/despacho',
      component: lazy(() => retry(() => import('@pages/Site/Dispatch')))
    },
    {
      exact: true,
      path: '/evaluanos',
      component: lazy(() => retry(() => import('@pages/Site/Evaluate')))
    },
    {
      exact: true,
      path: '/:slug',
      component: lazy(() => retry(() => import('@pages/Site/HomePage')))
    },
    {
      // eslint-disable-next-line
      component: () => ({ to = '/404' }) => <Redirect to={to} />
    }
  ]
}
