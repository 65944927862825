import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as ProductsLayout } from '@pages/Admin/Products/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/products/:action?/:productId?',
  guard: true,
  layout: ProductsLayout,
  navs: [
    { name: 'Información', path: '/admin/products/information/:productId' },
    { name: 'Modificadores', path: '/admin/products/modifiers/:productId' },
    { name: 'Disponibilidad y Precio', path: '/admin/products/availability/:productId' },
    { name: 'Upselling', path: '/admin/products/upselling/:productId' },
    { name: 'Match', path: '/admin/products/match/:productId' },
    { name: 'Eliminar', path: '/admin/products/delete/:productId' }
  ],
  excludedPaths: ['/admin/products'],
  routes: [
    {
      exact: true,
      path: '/admin/products',
      component: lazy(() => retry(() => import('@pages/Admin/Products')))
    },
    {
      exact: true,
      path: '/admin/products/information/',
      component: lazy(() => retry(() => import('@pages/Admin/Products/InformationNew')))
    },
    {
      exact: true,
      path: '/admin/products/information/:productId',
      component: lazy(() => retry(() => import('@pages/Admin/Products/Information')))
    },
    {
      exact: true,
      path: '/admin/products/modifiers/:productId',
      component: lazy(() => retry(() => import('@pages/Admin/Products/Modifiers')))
    },
    {
      exact: true,
      path: '/admin/products/availability/:productId',
      component: lazy(() => retry(() => import('@pages/Admin/Products/Availability')))
    },
    {
      exact: true,
      path: '/admin/products/upselling/:productId',
      component: lazy(() => retry(() => import('@pages/Admin/Products/Upselling')))
    },
    {
      exact: true,
      path: '/admin/products/match/:productId',
      component: lazy(() => retry(() => import('@pages/Admin/Products/Match')))
    },
    {
      exact: true,
      path: '/admin/products/delete/:productId',
      component: lazy(() => retry(() => import('@pages/Admin/Products/Delete')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
