import React, { useEffect } from 'react'

import useGoogleAnalytics from '@hooks/useGoogleAnalytics'
import miles from '@utils/miles'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _map from 'lodash/map'
import './styles.scss'

CartItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  cart: PropTypes.array,
  setCart: PropTypes.func
}

export default function CartItem({ item, index, cart, setCart }) {
  const {
    name,
    quantity,
    notFound = false,
    discount = 0,
    price = 0,
    optionalsPrice = 0,
    optionals = [],
    special_instructions: si
  } = item

  const discountTotal = (discount + optionalsPrice) * quantity
  const priceTotal = (price + optionalsPrice) * quantity
  const useGA = useGoogleAnalytics()

  const changeQuantity = (newQuantity) => {
    const updatedItem = Object.assign(item, { quantity: newQuantity })
    const newCart = cart.map((a) => ({ ...a }))
    newCart[index] = updatedItem
    setCart(newCart)
  }

  const deleteItem = () => {
    // GA4 event
    useGA.removeFromCart(cart[index])

    const newCart = cart.map((a) => ({ ...a }))
    newCart.splice(index, 1)
    setCart(newCart)
  }

  useEffect(() => {
    changeQuantity(quantity)
    // eslint-disable-next-line
  }, [quantity])

  return (
    <div className="cart-item">
      <div className="cart-item__product">
        <select
          className="custom-select"
          defaultValue={quantity}
          value={quantity}
          onChange={(evt) => changeQuantity(evt.target.value)}
        >
          {[1, 2, 3, 4, 5, 6].map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className="cart-item__description">
          <span>{name}</span>
        </div>
        <div className="cart-item__price">
          {discount > 0 ? (
            <>
              <span className="sale">${miles(priceTotal)}</span>
              <span className="font-weight-bold">${miles(discountTotal)}</span>
            </>
          ) : (
            <span className="font-weight-bold">${miles(priceTotal)}</span>
          )}
        </div>
      </div>
      <div className="cart-item__action">
        <div className="cart-item__optionals">
          {_map(optionals, (option, index) => (
            <div className="cart-item__option" key={index}>
              <span>{option.name}</span>
              {_get(option, 'items', []).map((item, index2) => (
                <span key={index2}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-{item.name}</span>
              ))}
            </div>
          ))}
          {si && (
            <div className="cart-item__optionals">
              <div className="cart-item__option">
                <span>Comentario:</span>
                <span>{si}</span>
              </div>
            </div>
          )}
        </div>
        <span className="cart-item__delete" onClick={deleteItem}>
          Eliminar
        </span>
      </div>
      {/* {notFound && <div className="cart-item__notfound">Este producto está agotado</div>} */}
      {notFound && (
        <div className="cart-item__notfound">
          Por favor eliminar o sustituir el producto agotado para continuar
        </div>
      )}
    </div>
  )
}
