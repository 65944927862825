/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { useCheckout } from '@hooks/useCheckout'
import useGoogleMaps from '@hooks/useGoogleMaps'
import { useProducts } from '@hooks/useProducts'
import PropTypes from 'prop-types'

import _get from 'lodash/get'
import _has from 'lodash/has'
import _merge from 'lodash/merge'

SelectMaps.propTypes = {
  manualInitialAddress: PropTypes.object,
  startEmpty: PropTypes.bool
}

export default function SelectMaps({ manualInitialAddress = {}, startEmpty = true }) {
  const [checkout, setCheckout] = useCheckout()
  const [, , getProducts] = useProducts()
  const { address: initialAddress } = checkout
  const { options, detail, findPlaces, getDetail } = useGoogleMaps()
  const additionalInformation = _get(checkout, 'additional_information', '')
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const DPI = _get(detail, 'place_id')
  const checkoutPlaceId = _get(checkout, 'address.place_id')

  // useEffect(() => {
  //   if (!startEmpty) {
  //     if (
  //       _has(manualInitialAddress, 'place_id') &&
  //       DPI !== _get(manualInitialAddress, 'place_id')
  //     ) {
  //       setFlag(true)
  //       findPlaces(manualInitialAddress.address)
  //       getDetail(manualInitialAddress.place_id)
  //     } else if (_has(initialAddress, 'place_id') && DPI !== _get(initialAddress, 'place_id')) {
  //       setFlag(true)
  //       findPlaces(initialAddress.address)
  //       getDetail(initialAddress.place_id)
  //     }
  //   }
  //   setLoading(false)
  //   console.log('first')
  //   // eslint-disable-next-line
  // }, [])
  useEffect(() => {
    if (DPI && checkoutPlaceId !== DPI && flag) {
      setCheckout({
        field: 'address',
        value: _merge({}, flag ? _get(checkout, 'address', {}) : {}, flag ? detail : {}, {
          additional_information: additionalInformation
        })
      })
      setCheckout({
        field: 'delivery',
        value: _get(detail, 'delivery', false)
      })
      setCheckout({
        field: 'delivery_fee',
        value: _get(detail, 'store.delivery_fee', 0)
      })
      setCheckout({
        field: 'store_id',
        value: _get(detail, 'store.id', '')
      })
      setCheckout({
        field: 'store_name',
        value: _get(detail, 'store.name', '')
      })

      if (detail.delivery) {
        getProducts(_get(detail, 'store.id', ''))
      }
    }
    setLoading(false)
  }, [
    DPI,
    checkout,
    detail,
    setCheckout,
    flag,
    checkoutPlaceId,
    getProducts,
    additionalInformation
  ])

  const hasNumber = (myString) => {
    return /\d/.test(myString)
  }

  const handleInputChange = (value) => {
    if (value.length > 2 && hasNumber(value)) {
      findPlaces(value)
    }
  }

  const handleChange = async (option) => {
    if (option === null) {
      setCheckout({
        field: 'address',
        value: {}
      })
      setFlag(false)
    } else if (_has(option, 'value')) {
      await getDetail(option.value)
      setFlag(true)
    }
  }

  if (loading) {
    return null
  }

  return (
    <Select
      autoFocus={false}
      backspaceRemovesValue={true}
      className="select-outline"
      classNamePrefix="select-outline"
      defaultValue={
        _has(initialAddress, 'place_id')
          ? {
              value: _get(initialAddress, 'place_id'),
              label:
                _get(initialAddress, 'address') +
                ', ' +
                _get(initialAddress, 'secondary_information')
            }
          : null
      }
      isClearable={true}
      noOptionsMessage={() =>
        'Escribe tu dirección con calle y número para que aparezcan los resultados'
      }
      options={options}
      placeholder="Dirección"
      onChange={handleChange}
      onFocus={(e) => {
        if (e.target.autocomplete) {
          e.target.autocomplete = 'nope'
        }
      }}
      onInputChange={handleInputChange}
    />
  )
}
