import React, { createContext, useContext, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

export const StickyCartContext = createContext({
  toogleCart: false,
  setToogleCart: () => {}
})

export const StickyCartProvider = ({ children }) => {
  const [toogleCart, setToogleCart] = useState(false)

  useEffect(() => {
    setToogleCart(toogleCart)
    // eslint-disable-next-line
  }, [toogleCart])

  return (
    <StickyCartContext.Provider value={[toogleCart, setToogleCart]}>
      {children}
    </StickyCartContext.Provider>
  )
}

StickyCartProvider.propTypes = {
  initialState: PropTypes.array,
  children: PropTypes.any
}

export const useStickyCart = () => useContext(StickyCartContext)
