import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import useGoogleAnalytics from '@hooks/useGoogleAnalytics'
import axiosInstance from '@services/axios'

import _get from 'lodash/get'
import _has from 'lodash/has'

import { useAuthContext } from './context'

export default function useAuth() {
  const [state, dispatch] = useAuthContext()
  const { push } = useHistory()
  const { addToast } = useToasts()
  const useGA = useGoogleAnalytics()

  const errorHandler = (err) => {
    if (_has(err, 'response.status')) {
      if (err.response.status === 401) {
        cleanUp()
      }
      if (err.response.status === 403) {
        actions.logout()
      }
    }
  }

  const getError = (error) => {
    const errorMsg = _get(error, 'response.data.error.message')
    if (errorMsg) {
      addToast(errorMsg, { appearance: 'error' })
      errorHandler(error)
    }
  }

  const cleanUp = () => {
    delete axiosInstance.defaults.headers.common.Authorization
    window.localStorage.clear()
    window.sessionStorage.clear()
    push('/login')
    window.location.reload()
  }

  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      errorHandler(err)
      return Promise.reject(err)
    }
  )

  const actions = {
    reset: () => {
      dispatch({ profile: null, accessToken: null })
    },
    login: async (values) => {
      try {
        const response = await axiosInstance.post('/auth/login', values)
        dispatch({
          profile: _get(response, 'data.user', {}),
          accessToken: _get(response, 'data.access_token.token', null)
        })
        // GA4 event
        useGA.login(_get(response, 'data.user', {}))

        return true
      } catch (err) {
        getError(err)
        dispatch({ profile: null, accessToken: null })
        return false
      }
    },
    logout: async () => {
      try {
        await axiosInstance.request({
          url: '/auth/logout',
          method: 'DELETE'
        })
      } finally {
        dispatch({ profile: null, accessToken: null })
        cleanUp()
      }
    },
    signUp: async (values) => {
      try {
        const response = await axiosInstance.post('/auth/signup', values)
        dispatch({
          profile: _get(response, 'data.user', {}),
          accessToken: _get(response, 'data.access_token.token', null)
        })
        // GA4 event
        useGA.signUp(_get(response, 'data.user', {}))

        return true
      } catch (err) {
        getError(err)
        dispatch({ profile: null, accessToken: null })
        return false
      }
    },
    setPhone: async (value) => {
      try {
        await axiosInstance.put('/users', { phone: value })
        dispatch({
          profile: { ...state.profile, phone: value },
          accessToken: state.accessToken
        })
      } catch (err) {
        getError(err)
        dispatch({
          profile: state.profile,
          accessToken: state.accessToken
        })
      }
    },
    sendCode: async (email) => {
      try {
        return await axiosInstance.post('/auth/send_code', { email })
      } catch (err) {
        getError(err)
        dispatch({
          profile: state.profile,
          accessToken: state.accessToken
        })
      }
    },
    getProfile: async () => {
      try {
        const response = await axiosInstance.get('/users/info')
        dispatch({ profile: response.data })
      } catch (err) {
        getError(err)
        dispatch({
          profile: state.profile,
          accessToken: state.accessToken
        })
      }
    },
    updateUser: async ({ type, values = {}, id }) => {
      try {
        switch (type) {
          case 'user': {
            await axiosInstance.put('/users', values)
            await actions.getProfile()
            break
          }
          case 'comunications': {
            await axiosInstance.put('/users/comunications', values)
            await actions.getProfile()
            break
          }
          case 'update_address': {
            await axiosInstance.put('/users/update_address/' + id, values)
            await actions.getProfile()
            break
          }
          case 'detach_address': {
            await axiosInstance.delete('/users/detach_address/' + id)
            await actions.getProfile()
            break
          }
          case 'attach_address': {
            await axiosInstance.post('/users/attach_address', values)
            await actions.getProfile()
            break
          }
          default:
            return
        }
      } catch (err) {
        getError(err)
        dispatch({
          profile: state.profile,
          accessToken: state.accessToken
        })
      }
    }
  }

  return [state, actions]
}
