import { useState, useEffect } from 'react'

import axiosInstance from '@services/axios'
import queryString from 'query-string'

import _get from 'lodash/get'
import _size from 'lodash/size'

const getQueryString = ({ page, limit }) => queryString.stringify({ page, limit })

const buildUrl = ({ url, method, options }) => {
  if (method === 'POST') {
    return url
  } else {
    const query = getQueryString(options)
    if (url.indexOf('?') !== -1) {
      return `${url}&${query}`
    } else {
      return `${url}?${query}`
    }
  }
}

export default function useApi({
  url: initialUrl = '',
  method = 'GET',
  headers = {},
  manual = false
}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const [statusError, setStatusError] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const [pagination, setPagination] = useState({
    total: 0,
    perPage: limit,
    lastPage: 1,
    page,
    setPage,
    setLimit
  })

  const execute = (data) => {
    setLoading(true)
    if (!loading) {
      const request = {
        url: buildUrl({ url: _get(data, 'url', initialUrl), method, options: { page, limit } }),
        method: _get(data, 'method', method),
        headers: _get(data, 'headers', headers),
        data: _get(data, 'data', {})
      }

      if (
        request.method === 'GET' ||
        (request.method === 'POST' && _size(request.data) > 0) ||
        (request.method === 'PUT' && _size(request.data) > 0)
      ) {
        setError('')
        setStatusError('')
        axiosInstance
          .request(request)
          .then((response) => {
            setData(_get(response, 'data.data', _get(response, 'data', [])))
            if (_get(response, 'data.pagination', false)) {
              setPagination(Object.assign({}, pagination, _get(response, 'data.pagination', {})))
            } else {
              setPagination(false)
            }
            setLoading(false)
          })
          .catch((err) => {
            setError(_get(err, 'response.data.message', ''))
            setStatusError(_get(err, 'response.status', ''))
            setLoading(false)
          })
      }
    }
  }

  useEffect(() => {
    if (!manual) {
      execute()
    }
    // eslint-disable-next-line
  }, [page, limit])

  return [{ data, loading, error, statusError, pagination }, execute]
}
