import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/clients/:action?/:clientId?',
  guard: true,
  layout: AdminLayout,
  navs: [
    { name: 'Información', path: '/admin/clients/information/:clientId' },
    { name: 'Pedidos', path: '/admin/clients/orders/:clientId' },
    { name: 'Editar', path: '/admin/clients/edit/:clientId' },
    { name: 'Devoluciones', path: '/admin/clients/devolutions/:clientId' },
    { name: 'Valoraciones', path: '/admin/clients/reviews/:clientId' },
    { name: 'Visitas', path: '/admin/clients/visits/:clientId' }
  ],
  excludedPaths: [
    '/admin/clients'
  ],
  routes: [
    {
      exact: true,
      path: '/admin/clients',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/List')))
    },
    {
      exact: true,
      path: '/admin/clients/information/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Information')))
    },

    {
      exact: true,
      path: '/admin/clients/orders/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Orders')))
    },
    {
      exact: true,
      path: '/admin/clients/points/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Points')))
    },
    {
      exact: true,
      path: '/admin/clients/devolutions/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Devolutions')))
    },
    {
      exact: true,
      path: '/admin/clients/reviews/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Reviews')))
    },
    {
      exact: true,
      path: '/admin/clients/visits/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Visits')))
    },
    {
      exact: true,
      path: '/admin/clients/edit/:clientId',
      component: lazy(() => retry(() => import('@pages/Admin/Clients/Edit')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
