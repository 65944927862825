import React from 'react'

import { useProducts } from '@hooks/useProducts'
import PropTypes from 'prop-types'
import { FaSearch } from 'react-icons/fa'

import _get from 'lodash/get'
import './style.scss'

SearchProducts.propTypes = {
  productSearched: PropTypes.string,
  setProductSearched: PropTypes.func,
  setToogleSearch: PropTypes.func
}

export default function SearchProducts({ productSearched, setProductSearched, setToogleSearch }) {
  const [products, setProducts] = useProducts()
  const onlyProducts = _get(products, 'onlyProducts', [])
  let timer = null

  const handleChange = (e) => {
    clearTimeout(timer)
    setProductSearched(e.target.value)
    const uniqueProds = new Set()

    timer = setTimeout(() => {
      const prodsFinded = onlyProducts.filter((prod) => {
        const searchMatch = prod.name.toLowerCase().includes(productSearched.toLowerCase())
        if (searchMatch && !uniqueProds.has(prod.id)) {
          uniqueProds.add(prod.id)
          return true
        }
        return false
      })

      setProducts({
        field: 'filtered',
        value: prodsFinded.length > 0 ? prodsFinded : []
      })
    }, 250)
  }

  const handleToggle = () => {
    setToogleSearch(true)
  }

  return (
    <>
      <div className="product_box" onClick={handleToggle}>
        <FaSearch className="icon" />
        <input
          name="productName"
          placeholder="Buscar productos"
          type="text"
          value={productSearched}
          onChange={handleChange}
        />
      </div>
    </>
  )
}
