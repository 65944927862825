import React, { lazy, Suspense, Fragment, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import FacebookPixel from 'react-facebook-pixel'
import { Switch, Route, Redirect } from 'react-router-dom'

import Loading from '@components/Loading'
import useAuth from '@hooks/useAuth'
import { default as AuthLayout } from '@pages/Auth/Layout'
import { default as SiteLayout } from '@pages/Site/Layout'
import { default as adminAudiences } from '@routes/admin/audiences'
import { default as adminCategoriesRoutes } from '@routes/admin/categories'
import { default as adminClientsRoutes } from '@routes/admin/clients'
import { default as adminConfigurationRoutes } from '@routes/admin/configuration'
import { default as adminCouponRoutes } from '@routes/admin/coupons'
import { default as adminDevolutionsRoutes } from '@routes/admin/devolutions'
import { default as adminGiftcardRoutes } from '@routes/admin/giftcards'
import { default as adminMailingsRoutes } from '@routes/admin/mailings'
import { default as adminMenusRoutes } from '@routes/admin/menus'
import { default as adminModifiersRoutes } from '@routes/admin/modifiers'
import { default as adminOrdersRoutes } from '@routes/admin/orders'
import { default as adminProductsRoutes } from '@routes/admin/products'
import { default as adminReportsRoutes } from '@routes/admin/reports'
import { default as adminReservationRoutes } from '@routes/admin/reservation'
import { default as adminReviewsRoutes } from '@routes/admin/reviews'
import { default as adminRolesRoutes } from '@routes/admin/roles'
import { default as adminStoresRoutes } from '@routes/admin/stores'
import { default as adminWebsiteRoutes } from '@routes/admin/webSite'
import { default as salepointConfigRoutes } from '@routes/salepoint/config'
import { default as salepointDeliveryRoutes } from '@routes/salepoint/delivery'
import { default as salepointOrdersRoutes } from '@routes/salepoint/orders'
import { default as salepointOutstockRoutes } from '@routes/salepoint/outstock'
import { default as settingsRoutes } from '@routes/settings'
// Salepoint
import { default as siteRoutes } from '@routes/site'

import _get from 'lodash/get'

const routesConfig = [
  {
    exact: true,
    path: '/404',
    layout: SiteLayout,
    component: lazy(() => import('@pages/Admin/NotFound'))
  },
  {
    exact: true,
    guard: true,
    credentials: ['admin', 'operador', 'marketing'],
    path: '/admin',
    component: <Redirect to={'/admin/orders'} />
  },
  {
    exact: true,
    path: '/salepoint',
    component: <Redirect to={'/salepoint/orders/new'} />
  },
  {
    exact: true,
    path: '/login/:token?',
    layout: AuthLayout,
    component: lazy(() => import('@pages/Auth'))
  },
  adminAudiences,
  adminOrdersRoutes,
  adminCouponRoutes,
  adminGiftcardRoutes,
  adminClientsRoutes,
  adminReviewsRoutes,
  adminDevolutionsRoutes,
  adminReservationRoutes,
  adminProductsRoutes,
  adminReportsRoutes,
  adminModifiersRoutes,
  adminRolesRoutes,
  adminStoresRoutes,
  adminMailingsRoutes,
  adminCategoriesRoutes,
  adminMenusRoutes,
  adminConfigurationRoutes,
  adminWebsiteRoutes,
  salepointConfigRoutes,
  salepointDeliveryRoutes,
  salepointOrdersRoutes,
  salepointOutstockRoutes,
  settingsRoutes,
  siteRoutes
]

export default function Routes() {
  const [{ accessToken }] = useAuth()

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: window.location.pathname,
        pageTitle: window.location.pathname,
      },
    });

    FacebookPixel.pageView();
    FacebookPixel.fbq('track', 'PageView');
  }, [])

  const renderComponent = (route, props) => {
    const Component = route.component
    return route.routes ? (
      renderRoutes(route.routes)
    ) : React.isValidElement(Component) ? (
      Component
    ) : (
      <Component {...props} />
    )
  }

  const renderLayout = (props, route) => {
    if (!accessToken && _get(route, 'guard')) {
      return <Redirect to="/login" />
    }
    const Layout = route.layout || Fragment
    if (Layout === Fragment) {
      return <>{renderComponent(route, props)}</>
    }
    const navs = route.navs || []
    const url = _get(props, 'match.url', '')
    const settings = route.settings || false
    const excludedPaths = route.excludedPaths || []
    Object.assign(props, { navs, excludedPaths, settings, url })
    return <Layout {...props}>{renderComponent(route, props)}</Layout>
  }

  const renderRoutes = (routes) => {
    return routes ? (
      <Suspense fallback={<Loading />}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                exact={route.exact}
                key={index}
                path={route.path}
                render={(props) => renderLayout(props, route)}
              />
            )
          })}
        </Switch>
      </Suspense>
    ) : null
  }

  return renderRoutes(routesConfig)
}