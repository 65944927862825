import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useConfig } from '@hooks/useConfig'
import useMixPanel from '@hooks/useMixPanel'

import classnames from 'classnames'
import PropType from 'prop-types'

import './style.scss'

const Vertical = ({ navbar }) => {
  const config = useConfig()
  const { track } = useMixPanel(config)

  const {
    links,
    files,
    background_color: bgColor,
    background_color_mobile: bgColorMobile,
    text_color: txColor,
    text_color_mobile: txColorMobile,
    toggler_color: togglerColor
  } = navbar
  const [activeNav, setActiveNav] = useState(false)

  const onScrollHide = () => {
    setActiveNav(false)
  }

  useEffect(() => {
    document.addEventListener('scroll', onScrollHide)
    return () => document.addEventListener('scroll', onScrollHide)
  })
  const { push } = useHistory()
  const handleClick = (link) => {
    if (link) {
      if (link === '/pedir') {
        track('HOME_BOTON_PEDIR', {
          link: link,
          isMobile: true,
          event: 'click'
        })
      }

      if (link.includes('http') || link.includes('mailto') || link.includes('tel:')) {
        window.open(link)
      } else {
        push(link)
      }
    }
  }

  if (!files[0]) {
    return null
  }

  return (
    <div
      className={classnames('nav-container', {
        'is-active': activeNav
      })}
      tabIndex="0"
    >
      <style>
        {`
          .nav-container .nav-toggle:after, .nav-container .nav-toggle:before {
            background: ${togglerColor};
          }
          .nav-container .nav-toggle:after {
            box-shadow: 0 -13.33333px 0 0 ${togglerColor};
          }
          .nav-container .nav-toggle:before {
            box-shadow: 0 13.33333px 0 0 ${togglerColor};
          }
        `}
      </style>
      <div
        className="nav-toggle"
        onClick={(e) => {
          e.stopPropagation()
          setActiveNav(!activeNav)
        }}
      ></div>

      <nav className="nav-items" style={{ backgroundColor: activeNav ? bgColorMobile : bgColor }}>
        <div className="logo">
          <Link to="/">
            <img alt={files[0].name} src={files[0].path} />
          </Link>
        </div>
        {links?.map((link) => (
          <div
            className="nav-item nav-link"
            key={link.id}
            style={{ color: activeNav ? txColorMobile : txColor }}
            onClick={() => handleClick(link.path)}
          >
            <span>{link.title}</span>
          </div>
        ))}
      </nav>
    </div>
  )
}

Vertical.propTypes = {
  navbar: PropType.object
}

export default Vertical
