import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import './style.scss'
import { useConfig } from '@hooks/useConfig'

import _each from 'lodash/each'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'

const footerSections = {
  know_us: 'Más información',
  social_networks: 'Redes sociales',
  account: 'Mi Cuenta'
}

export default function Footer() {
  const { push } = useHistory()
  const { sections, website } = useConfig()
  const footer = _find(sections, { type: 'footer' })

  const textColor = _get(footer, 'text_color')
  const bgColor = _get(footer, 'background_color')

  if (!footer) {
    return null
  }
  const handleClick = (link) => {
    if (link) {
      if (link.includes('http') || link.includes('mailto') || link.includes('tel:')) {
        window.open(link)
      } else {
        push(link)
      }
    }
  }

  const renderSection = () => {
    const sections = []
    _each(footerSections, (section, key) => {
      const links = _filter(_get(footer, 'links'), { type: key })
      if (links.length > 0) {
        sections.push(
          <div className="col-lg-3 col-md-6" key={key}>
            {key === 'account' ? (
              <Link to="/settings/profile">
                <h5>{section}</h5>
              </Link>
            ) : (
              <h5>{section}</h5>
            )}
            <ul className="list-unstyled">
              {links.map((link, index) => (
                <li key={index}>
                  <div
                    className="footer__link"
                    key={link.id}
                    onClick={() => handleClick(link.path)}
                  >
                    <span>{link.title}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      }
    })
    return sections
  }

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6" style={{ textAlign: 'center' }}>
              <div className="footer__newFooter">
                <Link className="footer__logo" to="/">
                  <img alt="Logo" src={_get(footer, 'files.0.path')} />
                </Link>
                {website !== 'delivery gourmet' && (
                  <><h5>es parte de</h5>
                  <a
                    className="footer__logo"
                    href="https://deliverygourmet.cl"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      alt="LogoDg"
                      src="https://delivery-gourmet-sites-app-files-resized.s3.amazonaws.com/delivery-gourmet/F138yNrFvCJgL1Oy2ur_TDpzQqvNMssTr8UejeTIt6.png"
                    />
                  </a>
                  </>
                )}
              </div>
              <hr className="footer__logo_seperador" />
            </div>
            {renderSection()}
          </div>
        </div>
      </div>
      <style>
        {`
        .footer {
          color: ${textColor};
          background-color: ${bgColor};
          }
        `}
      </style>
    </>
  )
}
