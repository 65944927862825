import React, { createContext, useContext, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import useSessionStorage from './useSessionStorage'

export const CartContext = createContext({ cart: [], setCart: () => {} })

export const CartProvider = ({ children }) => {
  const [cartStorage, setCartStorage] = useSessionStorage('cart', [])
  const [cart, setCart] = useState(cartStorage)

  useEffect(() => {
    setCartStorage(cart)
    // eslint-disable-next-line
  }, [cart])

  return <CartContext.Provider value={[cart, setCart]} >
    {children}
  </CartContext.Provider>
}
CartProvider.propTypes = {
  initialState: PropTypes.array,
  children: PropTypes.any
}

export const useCart = () => useContext(CartContext)
