/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useReducer, useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'

import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'

import _size from 'lodash/size'

import useSessionStorage from '../useSessionStorage'

import ProductsContext from './provider'
import productsReducer from './reducer'

export const useProducts = () => useContext(ProductsContext)
export const ProductsProvider = ({ children }) => {
  const [productsStorage] = useSessionStorage('products', [])
  const [products, setProducts] = useReducer(productsReducer, productsStorage)

  const { addToast } = useToasts()

  const [, executeAxios] = useAxios({}, { manual: true })

  /**
   * The function `buildRequest` makes a GET request to a specified URL and processes the response data
   * to extract and set products.
   * @param url - The `url` parameter is the URL of the API endpoint that you want to send a GET
   * request to.
   */
  const buildRequest = async (url) => {
    try {
      await executeAxios({
        url,
        method: 'GET'
      }).then((res) => {
        const { status, data } = res
        if (status === 200 && data.store_id !== '') {
          const { products_by_categories } = data
          if (products_by_categories) {
            const prodsArray = products_by_categories.map((prArr) => prArr.products)
            const prods = prodsArray.reduce((acc, current) => {
              return acc.concat(current)
            }, [])

            setProducts({
              field: 'data',
              value: data
            })
            setProducts({
              field: 'onlyProducts',
              value: prods
            })
          }
        }
      })
    } catch (error) {
      // addToast('Error en la obtención de productos', { appearance: 'error' })
    }
  }

  useEffect(() => {
    if (products && _size(products) === 0) {
      buildRequest('/products')
    }
  }, [products])

  /* The `getProducts` function is a callback function that is used to fetch products from an API
  endpoint. It takes an optional `store_id` parameter, which is used to filter the products based on
  a specific store. */
  const getProducts = useCallback(
    (store_id = '') => {
      try {
        const url_template = `/products${store_id !== '' ? `?store_id=${store_id}` : ''}`
        buildRequest(url_template)
      } catch (error) {
        addToast('Error en la obtención de productos', { appearance: 'error' })
      }
    },
    [buildRequest, addToast]
  )

  return (
    <ProductsContext.Provider value={[products, setProducts, getProducts]}>
      {children}
    </ProductsContext.Provider>
  )
}

ProductsProvider.propTypes = {
  children: PropTypes.any
}
