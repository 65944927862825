import React from 'react'
import { NavLink } from 'react-router-dom'

import { useRouter } from '@hooks/useRouter'
import PropTypes from 'prop-types'

import _each from 'lodash/each'
import './styles.scss'

const Tabs = ({ tabs }) => {
  const { query } = useRouter()
  return (
    <div className="tabs">
      <div className="container">
        <ul className="tabs__list">
          {tabs.map(({ name, path }, index) => {
            _each(query, (value, key) => {
              path = path.replace(`:${key}`, value)
            })
            return (
              <li className="tabs__item" key={index}>
                <NavLink className="tabs__link" to={path}>{name}</NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array
}

export default Tabs
