import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/reports/:action?/:couponId?',
  guard: true,
  layout: AdminLayout,
  // navs: [
  //   { name: 'Pedidos', path: '/admin/coupons/orders/:couponId' },
  //   { name: 'Reportes', path: '/admin/coupons/report/:couponId' },
  //   { name: 'Editar', path: '/admin/coupons/edit/:couponId' }
  // ],
  // excludedPaths: [
  //   '/admin/coupons',
  //   '/admin/coupons/create'
  // ],
  routes: [
    {
      exact: true,
      path: '/admin/reports',
      component: lazy(() => retry(() => import('@pages/Admin/Reports')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
