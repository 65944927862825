import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

RingDingDong.propTypes = {
  ringing: PropTypes.bool.isRequired
}

const ringSrc = '/campana.mp3'
const sound = new Audio(ringSrc)

export default function RingDingDong ({ ringing }) {
  const playRing = async () => {
    sound.addEventListener('ended', function () {
      this.currentTime = 0
      this.play()
    }, false)

    try {
      await sound.play()
      sound.muted = false
    } catch (error) {
      console.warn('Error making sound:', error)
    }
  }

  const stopRing = () => {
    sound.removeEventListener('ended', function () {
      this.currentTime = 0
      this.play()
    }, false)
    sound.muted = true
    sound.pause()
  }

  useEffect(() => {
    if (ringing) {
      playRing()
    } else {
      stopRing()
    }
    // eslint-disable-next-line
  }, [ringing])

  useEffect(() => {
    return () => {
      stopRing()
    }
    // eslint-disable-next-line
  },[])

  return <></>
}
