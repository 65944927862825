import React, { createContext, useEffect } from 'react'

import useAuth from '@hooks/useAuth'
import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'

export const OptionsContext = createContext()

const OptionsContextProvider = ({ children }) => {
  const modifierId = window.location.pathname.split('/')[4]

  const [{ accessToken }] = useAuth()

  const [{ data: optionalInfo, loading: loadingOptions }, executeGetOptionals] = useAxios({ url: 'dashboards/optionals/' + modifierId, headers: { Authorization: `Bearer ${accessToken}` } }, { manual: true, useCache: false })

  useEffect(() => {
    if (accessToken && modifierId) {
      executeGetOptionals()
    }
  }, [modifierId, executeGetOptionals, accessToken])

  return (
    <OptionsContext.Provider value={{ modifierId, optionalInfo, loadingOptions, executeGetOptionals }}>
      {children}
    </OptionsContext.Provider>
  )
}

OptionsContextProvider.propTypes = {
  children: PropTypes.any
}

export default OptionsContextProvider
