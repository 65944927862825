import React from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'
import './styles.scss'

const MenuItem = ({ title, icon, items }) => {
  return (
    <div className="menu-item">
      <div className="menu-item__title">
        {icon}
        <span>{title}</span>
      </div>
      <div className="menu-item__list">
        {items.map((item, index) => {
          return (
            <NavLink
              strict
              className="menu-item__link"
              key={index}
              to={item.path}
            >
              {item.title}
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

MenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  items: PropTypes.array
}

export default MenuItem
