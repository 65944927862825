import React, { useContext, useState, useEffect } from 'react'

import SearchSidebar from '@components/SearchSidebar'
import SearchSidebarItem from '@components/SearchSidebarItem'
import categories from '@pages/Admin/Layout/categories'
import SidebarMenu from '@pages/Admin/Layout/SidebarMenu'

import PropTypes from 'prop-types'

import OptionsContextProvider from '../OptionsContext'
import ModifierContextProvider, { ModifiersContext } from '../ModifierContext'

const Layout = ({ children }) => {
  return (
    <ModifierContextProvider>
      <OptionsContextProvider>
        <LayoutWithoutProviders>
          {children}
        </LayoutWithoutProviders>
      </OptionsContextProvider>
    </ModifierContextProvider>
  )
}

const LayoutWithoutProviders = ({ children }) => {
  const { modifiers: optionalsData = [] } = useContext(ModifiersContext)
  const [optionals, setOptionals] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    setOptionals(optionalsData)
  }, [optionalsData])

  useEffect(() => {
    const filteredOptionals = optionalsData.filter((optional) =>
      optional?.title?.toLowerCase().includes(query)
    )
    setOptionals(filteredOptionals)
    // eslint-disable-next-line
  }, [query])

  return (
    <main>
      <SidebarMenu categories={categories} />
      <div className="main-wrapper w-100">
        <div className="grid-layout">
          <SearchSidebar
            createLink="/admin/modifiers/configuration/"
            query={query}
            setQuery={setQuery}
          >
            <SearchSidebarItem items={optionals} />
          </SearchSidebar>
          <div className="grid-layout__wrapper p-0">
            {children}
          </div>
        </div>
      </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout
