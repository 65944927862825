import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/orders/:action?/:orderId?',
  guard: true,
  credentials: ['admin', 'operador', 'marketing'],
  layout: AdminLayout,
  navs: [],
  routes: [
    {
      exact: true,
      path: '/admin/orders',
      component: lazy(() => retry(() => import('@pages/Admin/Orders/List')))
    },
    {
      exact: true,
      path: '/admin/orders/detail/:orderId',
      component: lazy(() => retry(() => import('@pages/Admin/Orders/Detail')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
