import _has from 'lodash/has'

const updateProducts = (newProducts) => {
  window.sessionStorage.setItem('products', JSON.stringify(newProducts))
}

export default function reducer(state, action) {
  if (_has(action, 'field') && _has(action, 'value')) {
    const newProducts = { ...state, [action.field]: action.value }
    updateProducts(newProducts)
    return newProducts
  } else {
    updateProducts(state)
    return state
  }
}
