/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useCheckout } from '@hooks/useCheckout'
import { useConfig } from '@hooks/useConfig'
import PropTypes from 'prop-types'
import { FaRegUserCircle } from 'react-icons/fa'
import {
  MdAddCircleOutline,
  MdExitToApp,
  MdSettings,
  MdClose,
  MdShopTwo,
  MdStoreMallDirectory
} from 'react-icons/md'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _has from 'lodash/has'

import './style.scss'

NavBar.propTypes = {
  isBrandUser: PropTypes.bool,
  profile: PropTypes.object,
  isLogged: PropTypes.bool,
  dispatch: PropTypes.object,
  setIsLogged: PropTypes.func,
  setToogleNav: PropTypes.func
}

export default function NavBar({
  isBrandUser,
  profile,
  isLogged,
  dispatch,
  setIsLogged,
  setToogleNav
}) {
  const [checkout, setCheckout] = useCheckout('')
  const { sections } = useConfig()
  const { push } = useHistory()

  const [username, setUsername] = useState('')

  const navbar = _find(sections, { type: 'navbar' })
  const { links, text_color: txColor } = navbar

  const btnColor = _get(navbar, 'button_color')
  const btnTextColor = _get(navbar, 'button_text_color')

  const handleClick = (link) => {
    if (link) {
      if (link.includes('http') || link.includes('mailto') || link.includes('tel:')) {
        window.open(link)
      } else {
        push(link)
      }
    }
  }

  const handleLogout = () => {
    dispatch.logout()
    setIsLogged(false)
  }

  const handleParentClick = (event) => {
    if (event.target === event.currentTarget) {
      setToogleNav(false)
    }
  }

  const handleCloseNavBar = (event) => {
    event.stopPropagation()
    setToogleNav(false)
  }

  useEffect(() => {
    if (_has(profile, 'id')) {
      setUsername(_get(profile, 'name') + ' ' + _get(profile, 'last_name'))
    }

    if (isLogged && _has(profile, 'addresses') && !checkout?.address.place_id) {
      const addresses = _get(profile, 'addresses')
      const lastOrder = addresses?.filter((address) => address.lastOrder === true)

      if (lastOrder[0]) {
        const { store_id, type_order } = lastOrder[0]

        if (type_order === 'delivery') {
          setCheckout({ field: 'address', value: lastOrder[0] })
          setCheckout({ field: 'store_id', value: store_id })
        }
      }
    }
  }, [isLogged, profile, checkout, setCheckout])

  return (
    <>
      <div className="navbar-vw no-visible" onClick={handleParentClick}>
        <aside className="d-flex flex-column justify-content-start item-content-center no-visible">
          <div className="login-buttons">
            {!isLogged ? (
              <>
                <Link className="btn btn-register" to="/login?register">
                  Registrate
                </Link>
                <Link className="btn btn-secondary" to="/login">
                  Iniciar sesión
                </Link>
              </>
            ) : (
              <>
                <div className="user-options align-items-center">
                  <FaRegUserCircle className="icon-rounded" size={35} />
                  <span>{username}</span>
                </div>
              </>
            )}
          </div>

          {isLogged && (
            <>
              <div className="profile_menu">
                <ul>
                  <li className="profile_menu__item">
                    <Link to="/settings/pedidos">
                      <MdShopTwo size={20} />
                      <span>Mis pedidos</span>
                    </Link>
                  </li>
                  <li className="profile_menu__item">
                    <Link to="/settings">
                      <MdSettings size={20} />
                      <span>Mi cuenta</span>
                    </Link>
                  </li>
                  {isBrandUser && (
                    <>
                      <li className="profile_menu__item">
                        <Link to="/admin">
                          <MdAddCircleOutline size={20} />
                          <span>Admin</span>
                        </Link>
                      </li>
                      <li className="profile_menu__item">
                        <Link to="/salepoint">
                          <MdStoreMallDirectory size={20} />
                          <span>Puntos de venta</span>
                        </Link>
                      </li>
                    </>
                  )}
                  <li className="profile_menu__item">
                    <span onClick={handleLogout}>
                      <MdExitToApp size={20} />
                      <label>Cerrar sesión</label>
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
          <hr />

          <div className="nav_links">
            {links.map((link) => (
              <div
                className="nav_links__link"
                key={link.id}
                style={{ color: txColor }}
                onClick={() => handleClick(link.path)}
              >
                <span>{link.title}</span>
              </div>
            ))}
          </div>

          <label className="icon-close" onClick={handleCloseNavBar}>
            <MdClose />
          </label>
        </aside>
      </div>

      <style>
        {`
        .login-buttons > .btn-register {
          color: ${btnTextColor};
          background-color: ${btnColor};
        }
        `}
      </style>
    </>
  )
}
