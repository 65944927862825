/* eslint-disable complexity */
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useProducts } from '@hooks/useProducts'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'

import _get from 'lodash/get'

import './result.scss'
Results.propTypes = {
  productSearched: PropTypes.string,
  setToogleSearch: PropTypes.func,
  setProductSearched: PropTypes.func
}

export default function Results({ productSearched, setToogleSearch, setProductSearched }) {
  const { push } = useHistory()
  const [products] = useProducts()
  const filteredProducts = _get(products, 'filtered', [])

  const handleOnClick = (slug) => {
    setToogleSearch(false)
    push(`pedir/${slug}`)
  }

  const renderResults = () => {
    if (productSearched === '') {
      return <h6>Escriba el nombre del producto</h6>
    } else if (filteredProducts.length === 0) {
      return <h6>Producto no encontrado</h6>
    } else {
      return filteredProducts.map(({ slug, name, file }, index) => {
        return (
          <div
            className="fullsearch__box__product"
            key={index}
            onClick={() => {
              handleOnClick(slug)
            }}
          >
            <div className="fullsearch__box__product__image">
              <img alt={`${slug}`} src={`${file.path}`} />
            </div>
            <div className="fullsearch__box__product__name">{name}</div>
          </div>
        )
      })
    }
  }

  const handleClose = () => {
    setProductSearched('')
    setToogleSearch(false)
  }

  return (
    <>
      <div className="fullsearch no-visible shadow">
        <div className="fullsearch__box">
          <div className="d-flex justify-content-between">
            <h5>Resultados de búsqueda: </h5>
            <label className="fullsearch__box--close" onClick={handleClose}>
              <MdClose className="close-icon" size={20} />
            </label>
          </div>
          {renderResults()}
        </div>
      </div>
    </>
  )
}
