import React, { useState, useEffect } from 'react'

import { useCheckout } from '@hooks/useCheckout'
import { useProducts } from '@hooks/useProducts'
import PropTypes from 'prop-types'
import { FaMapMarkerAlt } from 'react-icons/fa'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'

import './style.scss'

StoreLocation.propTypes = {
  stores: PropTypes.array
}

export default function StoreLocation({ stores }) {
  const [checkout, setCheckout] = useCheckout()
  const [, , getProducts] = useProducts()
  const [storeId, setStoreId] = useState(_get(checkout, 'store_id', ''))
  const store = _find(stores, { id: storeId })

  useEffect(() => {
    getProducts(storeId)

    setCheckout({ field: 'store_id', value: storeId })
    setCheckout({ field: 'store_name', value: _get(store, 'name', '') })
    setCheckout({ field: 'store_open', value: _get(store, 'is_open', false) })
    // eslint-disable-next-line
  }, [storeId])

  return (
    <div className="store_box">
      <FaMapMarkerAlt className="icon" />
      <select value={storeId} onChange={(e) => setStoreId(e.target.value)}>
        <option disabled selected value="">
          Selecciona dirección de retiro
        </option>
        {_map(stores, (option, index) => {
          if (option.takeaway) {
            return (
              <option key={index} value={_get(option, 'id')}>
                {_get(option, 'name')}
              </option>
            )
          }
        })}
      </select>
    </div>
  )
}
