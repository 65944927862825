import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/reservation/:action?/:orderId?',
  guard: true,
  credentials: ['admin', 'operador', 'marketing'],
  layout: AdminLayout,
  navs: [],
  routes: [
    {
      exact: true,
      path: '/admin/reservation',
      component: lazy(() => retry(() => import('@pages/Admin/Reservation/List')))
    },
    {
      exact: true,
      path: '/admin/reservation/add',
      component: lazy(() => retry(() => import('@pages/Admin/Reservation/Add')))
    },
    {
      exact: true,
      path: '/admin/reservation/detail/:reservationId',
      component: lazy(() => retry(() => import('@pages/Admin/Reservation/Detail')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
