import React from 'react'
import FacebookPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import useMetaTags from 'react-metatags-hook'
import { Router } from 'react-router-dom'
import { UseSessionProvider } from 'react-session-hook'
import { ToastProvider } from 'react-toast-notifications'

import Loading from '@components/Loading'
import AuthProvider from '@hooks/useAuth/provider'
import { CartProvider } from '@hooks/useCart'
import { CheckoutProvider } from '@hooks/useCheckout'
import { ConfigContext } from '@hooks/useConfig'
import { GiftcardProvider } from '@hooks/useGiftcard'
import useMixPanel from '@hooks/useMixPanel'
import { ProductsProvider } from '@hooks/useProducts'
import { StickyCartProvider } from '@hooks/useStickyCart'
import { RGA4Provider } from '@keg-hub/rga4'
import getMeta from '@services/getMeta'
import useAxios from 'axios-hooks'
import { createBrowserHistory } from 'history'

import './App.scss'
import './services/errorHandler'
// import _size from 'lodash/size'
import _find from 'lodash/find'
import _get from 'lodash/get'

import Routes from './Routes'

const history = createBrowserHistory()
history.listen((location) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: location.pathname,
      pageTitle: location.pathname
    }
  })
  FacebookPixel.pageView()
  FacebookPixel.fbq('track', 'PageView')
})

const Content = () => {
  const [{ data: config, loading }] = useAxios({ url: '/configurations' })
  const { metaData, title, description } = getMeta(config)
  const { initialize } = useMixPanel(config)

  initialize(config)
  useMetaTags(metaData, [title, description])

  if (loading || !title) {
    return <Loading />
  }

  if (!config) {
    return null
  }

  const facebookPixel = _get(config, 'integrations.0.facebook_chat_app_id') // PIXEL FB
  const pixelDomain = _get(config, 'integrations.0.facebook_domain_id', '') // PIXEL FB - DOMINIO

  const ReactGAid = _get(config, 'integrations.0.google_analytics_id')
  const ReactGTMid = _get(config, 'integrations.0.google_tag_manager_id')

  if (ReactGTMid) {
    TagManager.initialize({ gtmId: ReactGTMid })
  }

  if (facebookPixel) {
    // const pixelOptions = {
    //   autoConfig: true,
    //   debug: false,
    // }
    // FacebookPixel.init(facebookPixel, {}, pixelOptions);
    FacebookPixel.init(facebookPixel)
  }

  const url = _get(_find(_get(metaData, 'metas', []), { name: 'url' }), 'content')
  const image = _get(metaData, 'openGraph.image', '')

  return (
    <>
      <Helmet>
        <meta content={pixelDomain} name="facebook-domain-verification" />
        <meta content={description} name="description" />
        <meta
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          name="robots"
        />
        <link href="https://yourapp.com" rel="canonical" />
        <meta content="es_ES" property="og:locale" />
        <meta content="website" property="og:type" />
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content={url} property="og:url" />
        <meta content={title} property="og:site_name" />
        <meta content={image} property="og:image" />
        <meta content={image} property="og:image:secure_url" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={description} name="twitter:description" />
        <meta content={title} name="twitter:title" />
        <meta content={image} name="twitter:image" />

        <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport"></meta>
      </Helmet>
      <ConfigContext.Provider value={config}>
        <ToastProvider autoDismiss={true}>
          <RGA4Provider code={ReactGAid}>
            <AuthProvider>
              <ProductsProvider>
                <GiftcardProvider>
                  <CartProvider>
                    <CheckoutProvider>
                      <UseSessionProvider>
                        <StickyCartProvider>
                          <Router history={history}>
                            <Routes />
                          </Router>
                        </StickyCartProvider>
                      </UseSessionProvider>
                    </CheckoutProvider>
                  </CartProvider>
                </GiftcardProvider>
              </ProductsProvider>
            </AuthProvider>
          </RGA4Provider>
        </ToastProvider>
      </ConfigContext.Provider>
    </>
  )
}

function App() {
  return <Content />
}

export default App
