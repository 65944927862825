import _find from 'lodash/find'
import _get from 'lodash/get'

export default function getMeta (data) {
  const seo = _find(_get(data, 'sections', []), { type: 'seo' })
  const title = _get(seo, 'title')
  const description = _get(seo, 'description')
  const files = _get(seo, 'files', [])
  const image = _get(_find(files, { type: 'image' }), 'path')
  const favicon = _get(_find(files, { type: 'favicon' }), 'path')

  const metaData = {
    title,
    description,
    charset: 'utf8',
    lang: 'es',
    metas: [
      { name: 'url', content: window.location.hostname },
      { name: 'keywords', content: 'a, list, of, keywords' },
      { 'http-equiv': 'Cache-Control', content: 'no-cache' }
      // { name: 'robots', content: 'index, follow' },
      // { name: 'DC.Title', content: 'Dublin Core Title' },
      // { property: 'fb:app_id', content: '1234567890' },
    ],
    links: [
      { rel: 'canonical', href: window.location.hostname },
      { rel: 'icon', type: 'image/ico', href: favicon },
      { rel: 'apple-touch-icon', sizes: '72x72', type: 'image/png', href: '/apple-72.png' }
    ],
    openGraph: {
      title,
      image,
      site_name: description
    }
    // twitter: {
    //   card: 'summary',
    //   creator: '@',
    //   title
    // }
  }

  return { metaData, title, description }
}
