import React, { useState, useEffect } from 'react'

import { useCart } from '@hooks/useCart'
import { useGiftcard } from '@hooks/useGiftcard'
import PropTypes from 'prop-types'
import { FaShoppingBag } from 'react-icons/fa'

import './style.scss'
CartButton.propTypes = {
  setToogleCart: PropTypes.func
}
export default function CartButton({ setToogleCart = null }) {
  const [cart] = useCart()
  const [giftcard] = useGiftcard()
  const [quantity, setQuantity] = useState(0)
  useEffect(() => {
    if (cart || giftcard) {
      let cartQuantity = 0
      let gifCartQuantity = 0

      if (cart) {
        cartQuantity = cart
          .map((pro) => parseInt(pro.quantity))
          .reduce((sum, quantity) => sum + quantity, 0)
      }

      if (giftcard) {
        gifCartQuantity = giftcard
          .map((gif) => gif.quantity)
          .reduce((sum, quantity) => sum + quantity, 0)
      }

      setQuantity(cartQuantity + gifCartQuantity)
    }
  }, [cart, giftcard])

  const handleOpenCart = () => {
    if (setToogleCart !== null) {
      setToogleCart(true)
    }
  }

  return (
    <div className="button-bg">
      <button aria-label="Carritos" data-test="cart-btn" type="button" onClick={handleOpenCart}>
        <FaShoppingBag className="icon" /> {quantity}
      </button>
    </div>
  )
}
