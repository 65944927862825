import React from 'react'

import { MdPerson } from 'react-icons/md'


export default [
  {
    id: 1,
    title: 'Ventas',
    icon: <MdPerson />,
    items: [
      { title: 'Punto de venta', path: '/salepoint/orders/new' },
      { title: 'Pedidos', path: '/admin/orders' },
      { title: 'Reservas', path: '/admin/reservation' },
      { title: 'Valoraciones', path: '/admin/reviews' },
    ]
  }
]