import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as SiteLayout } from '@pages/Site/Layout'
import retry from '@utils/retry'

const settingsProfile = '/settings/profile'

const navs = [
  { name: 'Perfil', path: settingsProfile },
  { name: 'Comunicación', path: '/settings/communication' },
  // { name: 'Pago', path: '/settings/payment' },
  { name: 'Mis direcciones', path: '/settings/addresses' },
  { name: 'Mis Reservas', path: '/settings/reservas' },
  { name: 'Mis Pedidos', path: '/settings/pedidos' }
]

export default {
  path: '/settings',
  guard: true,
  layout: SiteLayout,
  settings: true,
  navs,
  routes: [
    {
      exact: true,
      path: '/settings',
      component: <Redirect to={settingsProfile} />
    },
    {
      exact: true,
      path: settingsProfile,
      component: lazy(() => retry(() => import('@pages/Site/Settings/Profile')))
    },
    {
      exact: true,
      path: '/settings/payment',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Payment')))
    },
    {
      exact: true,
      path: '/settings/communication/mailtrackid/:token',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Comunication')))
    },
    {
      exact: true,
      path: '/settings/communication',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Comunication')))
    },
    {
      exact: true,
      path: '/settings/addresses',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Addresses/List')))
    },
    {
      exact: true,
      path: '/settings/addresses/create',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Addresses/Create')))
    },
    {
      exact: true,
      path: '/settings/addresses/:addressId',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Addresses/Address')))
    },
    {
      exact: true,
      path: '/settings/reservas',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Reservations')))
    },
    {
      exact: true,
      path: '/settings/reservas/:id',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Reservations/Reservation')))
    },
    {
      exact: true,
      path: '/settings/pedidos',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Orders')))
    },
    {
      exact: true,
      path: '/settings/security',
      component: lazy(() => retry(() => import('@pages/Site/Settings/Security')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
