import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Tabs from '@components/Tabs'
import { useRouter } from '@hooks/useRouter'
import PropTypes from 'prop-types'

import categories from './categories'
import operator from './operator'
import SidebarMenu from './SidebarMenu'
import useAuth from '@hooks/useAuth'

import './styles.scss'

const Layout = ({ navs, excludedPaths, children }) => {

  const [{ profile }] = useAuth()
  const { role = [] } = profile

  const { location } = useRouter()
  const isExcluded = excludedPaths.indexOf(location.pathname) !== -1
  const isLaptopCeil = useMediaQuery({ query: '(max-width: 1440px)' })

  const isXHDFloor = useMediaQuery({ query: '(max-width: 1441px)' })
  const container = isLaptopCeil ? 'container-md' : isXHDFloor ? 'container-lg' : 'container-xl'
  const menu = role.includes('admin') ? categories : operator

  return (
    <main className="main-wrapper">
      <SidebarMenu categories={menu} />
      <div
        className={container}
        style={{
          paddingTop: '40px',
          'margin-left': '0%',
          'margin-right': '0%',
          'max-width': '100%'
        }}
      >
        {!isExcluded && navs.length > 0 && <Tabs tabs={navs} />}
        {children}
      </div>
    </main>
  )
}

Layout.propTypes = {
  navs: PropTypes.array,
  excludedPaths: PropTypes.array,
  children: PropTypes.any
}

export default Layout
