import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import SalePointMenuItem from '@components/SalePointMenuItem'
import useInterval from '@hooks/useInterval'
import { useStores } from '@hooks/useStores'
import defaultUseAxios from 'axios-hooks'
import PropTypes from 'prop-types'
import { MdClose, MdMenu } from 'react-icons/md'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'

import RingDingDong from './RingDingDong'
import './styles.scss'

SidebarMenu.propTypes = {
  categories: PropTypes.array
}

export default function SidebarMenu ({ categories }) {
  const [openMenu, setOpenMenu] = useState(false)
  const [isRinging, setRinging] = useState(false)
  const [stores] = useStores()
  const storesId = _map(stores, (store) => _get(store, 'id', '')).join(',')

  const [{ data: counter }, refresh] = defaultUseAxios({
    url: (_size(stores) === 0)
      ? '/dashboards/point_sales/list_count_orders'
      : `/dashboards/point_sales/list_count_orders?stores=${storesId}`
  })

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  useInterval(() => {
    refresh()
  }, 10 * 1000)

  const counterNew = _get(counter, 'new', 0)

  useEffect(() => {
    if (counterNew > 0) {
      setRinging(true)
    } else {
      setRinging(false)
    }
  }, [counterNew])

  return (
    <div
      className={
        openMenu
          ? 'sidebar-menu-salepoint sidebar-menu--responsive'
          : 'sidebar-menu-salepoint'
      }
    >
      <RingDingDong ringing={isRinging} />
      <div className="sidebar-menu-salepoint__toggle">
        <button className="btn btn--none" onClick={handleToggleMenu}>
          {openMenu ? <MdClose size={30} /> : <MdMenu size={30} />}
        </button>
      </div>
      <div
        className={
          openMenu
            ? 'sidebar-menu-salepoint__wrapper active'
            : 'sidebar-menu-salepoint__wrapper'
        }
      >
        <div className="sidebar-menu-salepoint__logo">
          <Link to="/salepoint/config/stores">
            <span className="dlvry-puntodeventa-logo"/>
          </Link>
        </div>
        <div className="sidebar-menu-salepoint__list_sp">
          {categories.map(({ id, title, items, kind }) => {
            return (
              <SalePointMenuItem
                counter={counter}
                items={items}
                key={id}
                kind={kind}
                nochild={items.length === 1}
                title={title}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
