import React from 'react'

import { MdPerson, MdRestaurant, MdMonetizationOn, MdSettings } from 'react-icons/md'
/*
export default {
  admin : [
    {
      id: 1,
      title: 'Ventas',
      icon: <MdPerson />,
      items: [
        { title: 'Punto de venta', path: '/salepoint/orders/new' },
        { title: 'Pedidos', path: '/admin/orders' },
        { title: 'Clientes', path: '/admin/clients' },
        { title: 'Reservas', path: '/admin/reservation' },
        { title: 'Cupones', path: '/admin/coupons' },
        { title: 'Giftcards', path: '/admin/giftcards' },
        { title: 'Valoraciones', path: '/admin/reviews' },
        { title: 'Campañas', path: '/admin/mailings' }
      ]
    },
    {
      id: 2,
      title: 'Finanzas',
      icon: <MdMonetizationOn />,
      items: [
        { title: 'Reportes', path: '/admin/reports' },
        { title: 'Reportes Mailing', path: '/admin/mailings/reports' },
      ]
    },
    {
      id: 3,
      title: 'Menú',
      icon: <MdRestaurant />,
      items: [
        { title: 'Productos', path: '/admin/products' },
        { title: 'Categorías', path: '/admin/categories' },
        { title: 'Locales', path: '/admin/stores' },
        { title: 'Modificadores', path: '/admin/modifiers' }
      ]
    },
    {
      id: 4,
      title: 'Plataforma',
      icon: <MdSettings />,
      items: [
        { title: 'Roles', path: '/admin/roles' },
        { title: 'Configuración', path: '/admin/configuration' },
        { title: 'Sitio web', path: '/admin/website' }
      ]
    }
  ],
  operator : [
    {
      id: 1,
      title: 'Ventas',
      icon: <MdPerson />,
      items: [
        { title: 'Punto de venta', path: '/salepoint/orders/new' },
        { title: 'Pedidos', path: '/admin/orders' },
        { title: 'Reservas', path: '/admin/reservation' },
        { title: 'Valoraciones', path: '/admin/reviews' },
      ]
    }
  ]
}*/

export default [
    {
      id: 1,
      title: 'Ventas',
      icon: <MdPerson />,
      items: [
        { title: 'Punto de venta', path: '/salepoint/orders/new' },
        { title: 'Pedidos', path: '/admin/orders' },
        { title: 'Clientes', path: '/admin/clients' },
        { title: 'Reservas', path: '/admin/reservation' },
        { title: 'Cupones', path: '/admin/coupons' },
        { title: 'Giftcards', path: '/admin/giftcards' },
        { title: 'Valoraciones', path: '/admin/reviews' },
        { title: 'Campañas', path: '/admin/mailings' }
      ]
    },
    {
      id: 2,
      title: 'Finanzas',
      icon: <MdMonetizationOn />,
      items: [
        { title: 'Reportes', path: '/admin/reports' },
        { title: 'Reportes Mailing', path: '/admin/mailings/reports' },
      ]
    },
    {
      id: 3,
      title: 'Menú',
      icon: <MdRestaurant />,
      items: [
        { title: 'Productos', path: '/admin/products' },
        { title: 'Categorías', path: '/admin/categories' },
        { title: 'Locales', path: '/admin/stores' },
        { title: 'Modificadores', path: '/admin/modifiers' }
      ]
    },
    {
      id: 4,
      title: 'Plataforma',
      icon: <MdSettings />,
      items: [
        { title: 'Roles', path: '/admin/roles' },
        { title: 'Configuración', path: '/admin/configuration' },
        { title: 'Sitio web', path: '/admin/website' }
      ]
    }
  ]
