import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/website',
  guard: true,
  layout: AdminLayout,
  navs: [],
  routes: [
    {
      exact: true,
      path: '/admin/website',
      component: lazy(() => retry(() => import('@pages/Admin/WebSite/Pages')))
    },
    {
      exact: false,
      path: '/admin/website/create',
      component: lazy(() => retry(() => import('@pages/Admin/WebSite/Sections/Create')))
    },
    {
      exact: false,
      path: '/admin/website/:pageId',
      component: lazy(() => retry(() => import('@pages/Admin/WebSite/Sections')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
