import React, { useEffect, useReducer } from 'react'
import useSession from 'react-session-hook'

import reducer from '@hooks/useAuth/reducer'
import useLocalStorage from '@hooks/useLocalStorage'
import axiosInstance from '@services/axios'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import { AuthContext } from './context'

AuthProvider.propTypes = {
  initialProfile: PropTypes.object,
  initialAccessToken: PropTypes.string,
  setLocalAccessToken: PropTypes.func,
  children: PropTypes.any
}

export default function AuthProvider ({ children }) {
  const session = useSession()
  const [profile, setProfile] = useLocalStorage('profile', {})
  const [accessToken, setAccessToken] = useLocalStorage('accessToken')
  const [state, dispatch] = useReducer(reducer, { profile, accessToken })

  const updatedProfile = _get(state, 'profile.id', null)
  const updatedAccessToken = _get(state, 'accessToken')

  useEffect(() => {
    if (updatedProfile) {
      setProfile(updatedProfile)
    } else {
      setProfile({})
    }
    // eslint-disable-next-line
  }, [updatedProfile])

  useEffect(() => {
    if (updatedAccessToken) {
      setAccessToken(updatedAccessToken)
      session.setSession({ accessToken: updatedAccessToken })
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${updatedAccessToken}`
      axiosInstance.get('/users/info')
        .then(response => {
          dispatch({ profile: response.data })
        })
        .catch(() => {
          dispatch({ profile: null, accessToken: null })
        })
    } else {
      session.removeSession()
      dispatch({ profile: {}, accessToken: '' })
    }
    // eslint-disable-next-line
  }, [updatedAccessToken])

  return <AuthContext.Provider value={[state, dispatch]} >
    {children}
  </AuthContext.Provider>
}
