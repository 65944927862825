import React from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import _get from 'lodash/get'
import './styles.scss'

SalePointMenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  items: PropTypes.array,
  nochild: PropTypes.any,
  kind: PropTypes.string,
  counter: PropTypes.array
}

export default function SalePointMenuItem ({ title, items, nochild, kind, counter }) {
  return (
    <div className={`menu-item_salepoint ${nochild ? 'nochild' : ''}`}>
      <span className="menu-item__counter">
        {_get(counter, kind) > 0 && _get(counter, kind)}
      </span>
      {items.map((item, index) => {
        return (
          <>
            <NavLink
              strict
              className="menu-item__link"
              key={index}
              to={item.path}
            >
              {item.icon}
            </NavLink>
            <div className="menu-item-tooltip">
              <span>{item.title}</span>
            </div>
          </>
        )
      })}
    </div>
  )
}
