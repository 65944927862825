import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/categories',
  guard: true,
  layout: AdminLayout,
  navs: [],
  routes: [
    {
      exact: false,
      path: '/admin/categories',
      component: lazy(() => retry(() => import('@pages/Admin/Categories/Categories')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
