import { useEffect, useState } from 'react'

import Mixpanel from 'mixpanel'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'

let mixPanel = {}
export default function useMixPanel(config) {
    const [website, setWebsite] = useState(_get(config,'website', ''))
    const [token, setToken] = useState(_get(config, 'integrations.0.mixpanel_id'))

    useEffect(() => {
        setWebsite(_get(config, 'website'))
        setToken(_get(config, 'integrations.0.mixpanel_id'))
    }, [config, website, token])

    /*** Inicializamos MixPanel */
    const initialize = _debounce(() => {
        if (token){
            mixPanel = Mixpanel.init(token,{
                keepAlive: true,
            })
        }
    }, 250)
    
    /** Enviamos el tracking a MixPanel */
    const track = _debounce(async (name,data) => {
        if(token && data){
            data.distinct_id = String(website).toUpperCase()
            mixPanel.track(name,data)
        }
    }, 250)
        
    return { initialize, track }
}