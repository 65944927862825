import React from 'react'
import { NavLink } from 'react-router-dom'

import { useRouter } from '@hooks/useRouter'
import PropTypes from 'prop-types'

import _each from 'lodash/each'
import './styles.scss'

const SearchSidebarItem = ({ items }) => {
  const { query } = useRouter()
  return (
    <>
      {items?.map(({ id, title }, index) => {
        let path = '/admin/modifiers/detail/:modifierId'
        _each(query, (value, key) => {
          path = path.replace(`:${key}`, id)
        })
        return (
          <NavLink className="card card--sidebar-item" key={index}
            to={path}>
            <div className="card-body">{title}</div>
          </NavLink>
        )
      })}
    </>
  )
}

SearchSidebarItem.propTypes = {
  items: PropTypes.array
}

export default SearchSidebarItem
