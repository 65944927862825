import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/audiences/:action?/:productId?',
  guard: true,
  layout: AdminLayout,
  navs: [],
  excludedPaths: ['/admin/audiences'],
  routes: [
    {
      exact: true,
      path: '/admin/audiences',
      component: lazy(() => retry(() => import('@pages/Admin/Audiences')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
