import React, { createContext, useEffect } from 'react'

import useAuth from '@hooks/useAuth'
import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'

export const ProductsContext = createContext({ products: [], loadingProducts: false, executeGetProducts: null })

const ProductsContextProvider = ({ children }) => {
  const [{ accessToken }] = useAuth()

  const [{ data: products, loading: loadingProducts }, executeGetProducts] = useAxios({ url: '/dashboards/products', headers: { Authorization: `Bearer ${accessToken}` } }, { manual: true, useCache: false })

  useEffect(() => {
    if (accessToken) {
      executeGetProducts()
    }
  }, [executeGetProducts, accessToken])

  return (
    <ProductsContext.Provider value={{ products, loadingProducts, executeGetProducts }}>
      {children}
    </ProductsContext.Provider>
  )
}

ProductsContextProvider.propTypes = {
  children: PropTypes.any
}

export default ProductsContextProvider
