import _get from 'lodash/get'
import _has from 'lodash/has'

export const initialState = {
  address: { additional_information: '' },
  delivery_fee: 0,
  how_much: 0,
  orderOption: 'search',
  order_now: true,
  order_time: '',
  payment_type: '',
  store_id: '',
  store_name: '',
  tip_amount: 0,
  total: 0,
  type_order: 'delivery',
  store_open: false
}

const updateCheckout = (newCheckout) => {
  window.sessionStorage.setItem('checkout', JSON.stringify(newCheckout))
}

export default function reducer(state, action) {
  if (_has(action, 'action')) {
    switch (_get(action, 'action')) {
      case 'reset': {
        updateCheckout(initialState)
        return initialState
      }
      case 'removeCoupon': {
        const newCheckout = { ...state }
        delete newCheckout.coupon_id
        delete newCheckout.coupon_name
        delete newCheckout.coupon_discount
        updateCheckout(newCheckout)
        return newCheckout
      }
      case 'removeAddress': {
        const newCheckout = { ...state }
        delete newCheckout.address
        updateCheckout(newCheckout)
        return newCheckout
      }
      default: {
        updateCheckout(state)
        return state
      }
    }
  }
  if (_has(action, 'field') && _has(action, 'value')) {
    const newCheckout = { ...state, [action.field]: action.value }
    updateCheckout(newCheckout)
    return newCheckout
  } else {
    updateCheckout(state)
    return state
  }
}