/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import CartItem from '@components/CartItem/CartItem'
import Summary from '@components/Summary'
import { useCart } from '@hooks/useCart'
import { useCheckout } from '@hooks/useCheckout'
import { useConfig } from '@hooks/useConfig'
import { useGiftcard } from '@hooks/useGiftcard'
import useMixPanel from '@hooks/useMixPanel'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _size from 'lodash/size'

import './styles.scss'

export default function Cart() {
  const config = useConfig()
  const { addToast } = useToasts()
  const { track } = useMixPanel(config)
  const { push } = useHistory()
  const [cart, setCart] = useCart()
  const [giftcard, setGiftcard] = useGiftcard()
  const [checkout] = useCheckout()
  const [disabled, setDisabled] = useState(true)

  const redirectCheckout = () => {
    if (!disabled) {
      const { address, store_id, type_order } = checkout

      if (type_order === 'delivery' && !address) {
        addToast('Antes de proceder debe indicar dirección del delivery', {
          appearance: 'warning'
        })
        return false
      } else if (type_order === 'takeaway' && store_id === '') {
        addToast('Antes de proceder debe seleccionar sucursal de retiro', {
          appearance: 'warning'
        })

        return false
      }

      track('PEDIR_CONTINUAR', {
        link: '/checkout',
        event: 'click'
      })
      push('/checkout')
    }
  }

  useEffect(() => {
    setDisabled(_size(_filter(cart, { notFound: true })) > 0)
  }, [cart])

  return (
    <div className="cart">
      {_size(cart) > 0 || _size(giftcard) > 0 ? (
        <>
          <div className="cart__list">
            {cart.map((item, index) => (
              <CartItem cart={cart} index={index} item={item} key={index} setCart={setCart} />
            ))}
            {giftcard.map((item, index) => (
              <CartItem
                cart={giftcard}
                index={index}
                item={item}
                key={index}
                setCart={setGiftcard}
              />
            ))}
          </div>
          <Summary />
          <div className="cart__action">
            <button className="btn btn--color" disabled={disabled} onClick={redirectCheckout}>
              Continuar con mi {_get(checkout, 'type_order', '') === 'serve' ? 'reserva' : 'pedido'}
            </button>
          </div>
        </>
      ) : (
        <div className="cart__empty">
          <span>Cuando agregues productos se verán aquí</span>
        </div>
      )}
    </div>
  )
}
