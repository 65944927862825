import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/coupons/:action?/:couponId?',
  guard: true,
  layout: AdminLayout,
  navs: [
    { name: 'Pedidos', path: '/admin/coupons/orders/:couponId' },
    { name: 'Reportes', path: '/admin/coupons/report/:couponId' },
    { name: 'Editar', path: '/admin/coupons/edit/:couponId' }
  ],
  excludedPaths: ['/admin/coupons', '/admin/coupons/create'],
  routes: [
    {
      exact: true,
      path: '/admin/coupons',
      component: lazy(() => retry(() => import('@pages/Admin/Coupons/List')))
    },
    {
      exact: true,
      path: '/admin/coupons/create',
      component: lazy(() => retry(() => import('@pages/Admin/Coupons/CouponForm')))
    },
    {
      exact: true,
      path: '/admin/coupons/orders/:couponId',
      component: lazy(() => retry(() => import('@pages/Admin/Coupons/Orders')))
    },
    {
      exact: false,
      path: '/admin/coupons/report/:couponId',
      component: lazy(() => retry(() => import('@pages/Admin/Coupons/Report')))
    },
    {
      exact: false,
      path: '/admin/coupons/edit/:couponId',
      component: lazy(() => retry(() => import('@pages/Admin/Coupons/CouponForm')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
