import React, { createContext, useContext, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import useSessionStorage from './useSessionStorage'

export const GiftcardContext = createContext({ giftcard: [], setGiftcard: () => {} })

export const GiftcardProvider = ({ children }) => {
  const [giftcardStorage, setGiftcardStorage] = useSessionStorage('giftcard', [])
  const [giftcard, setGiftcard] = useState(giftcardStorage)

  useEffect(() => {
    setGiftcardStorage(giftcard)
    // eslint-disable-next-line
  }, [giftcard])

  return (
    <GiftcardContext.Provider value={[giftcard, setGiftcard]}>{children}</GiftcardContext.Provider>
  )
}
GiftcardProvider.propTypes = {
  initialState: PropTypes.array,
  children: PropTypes.any
}

export const useGiftcard = () => useContext(GiftcardContext)
