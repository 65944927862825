import React, { createContext, useContext, useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import useLocalStorage from './useLocalStorage'

export const StoresContext = createContext({ stores: [], setStores: () => {} })

export const StoresProvider = ({ children }) => {
  const [storesStorage, setStoresStorage] = useLocalStorage('stores', [])
  const [stores, setStores] = useState(storesStorage)

  useEffect(() => {
    setStoresStorage(stores)
  }, [stores, setStoresStorage])

  return <StoresContext.Provider value={[stores, setStores]} >
    {children}
  </StoresContext.Provider>
}

StoresProvider.propTypes = {
  children: PropTypes.any
}

export const useStores = () => useContext(StoresContext)
