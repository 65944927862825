import React, { useContext, useEffect, useReducer } from 'react'

import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'

import _each from 'lodash/each'
import _get from 'lodash/get'
import _size from 'lodash/size'

import { useCart } from '../useCart'
import useSessionStorage from '../useSessionStorage'

import CheckoutDispatch from './provider'
import checkoutReducer, { initialState } from './reducer'

export const CheckoutProvider = ({ children }) => {
  const [cart, setCart] = useCart([])
  const [checkoutStorage] = useSessionStorage('checkout', initialState)
  const [checkout, setCheckout] = useReducer(checkoutReducer, checkoutStorage)
  const [{ data, loading }, checkCart] = useAxios({}, { manual: true })
  const storeId = _get(checkout, 'store_id', '')

  useEffect(() => {
    if (storeId && _size(cart) > 0) {
      checkCart({
        url: '/cart/validate',
        method: 'POST',
        data: {
          ...checkout,
          products: cart
        }
      })
    }
    // eslint-disable-next-line
  }, [window.location.href, storeId])

  useEffect(() => {
    if (!loading && _size(cart) > 0) {
      const newCart = [...cart]
      _each(newCart, (product, index) => {
        newCart[index].notFound = false
      })
      _each(_get(data, 'product_not_found', []), (productNotFound) => {
        _each(newCart, (product, index) => {
          if ((_get(productNotFound, 'id') === _get(product, 'id'))) {
            newCart[index].notFound = true
          }
        })
      })
      setCart(newCart)
    }
    // eslint-disable-next-line
  }, [data, loading])

  return <CheckoutDispatch.Provider value={[checkout, setCheckout]} >
    {children}
  </CheckoutDispatch.Provider>
}

CheckoutProvider.propTypes = {
  children: PropTypes.any
}

export const useCheckout = () => useContext(CheckoutDispatch)
