import React from 'react'
import { Link } from 'react-router-dom'

import { useConfig } from '@hooks/useConfig'
import PropTypes from 'prop-types'

import _find from 'lodash/find'
import _get from 'lodash/get'
import './styles.scss'

const Layout = ({ children }) => {
  const { sections } = useConfig()
  const login = _find(sections, { type: 'login' })
  const files = _get(login, 'files', [])
  const loginImage = _find(files, { type: 'image' }) || []
  const logoImage = _find(files, { type: 'logo' }) || []

  const textColor = _get(login, 'text_color')
  const bgColor = _get(login, 'background_color')

  const btnColor = _get(login, 'button_color')
  const btnTextColor = _get(login, 'button_text_color')

  return (
    <>
      <div className="auth">
        <div className="auth__container">
          <Link className="auth__logo" to="/">
            <img alt="Logo" className="img-fluid" src={logoImage.path} />
          </Link>
          {children}
        </div>
        <div className="auth__bg">
          <img alt="Background" src={loginImage.path} />
        </div>
      </div>
      <style>
        {`
        :root {
          --clr-text: ${textColor};
          --clr-btn: ${btnColor};
          --clr-bg: ${bgColor};
          }
        `}
        {`
        .auth__container {
          color: ${textColor};
          background-color: ${bgColor};
          }
        `}
        {`
        .btn {
          color: ${btnTextColor};
          background-color: ${btnColor};
          }
        `}
      </style>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout
