import { createGlobalStyle } from 'styled-components'
export default createGlobalStyle`
  h1, h2, .title{
    font-family: ${(props) => props.theme.title};
  }

  h3, h4, h5, h6, .subtitle {
    font-family: ${(props) => props.theme.subtitle};
  }

  p, span {
    font-family: ${(props) => props.theme.body};
  }
`
