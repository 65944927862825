import React from 'react'
import PropType from 'prop-types'
import { useConfig } from '@hooks/useConfig'
import _get from 'lodash/get'
import './style.scss'

const Loading = ({ container }) => {
  const { design } = useConfig()
  const preloader = _get(design, 'preloader')
  
  return (
    <div className={container ? 'holder-container' : 'holder'}>
      <div className="preloader" style={{ background: `url(${preloader}) no-repeat center center` }}>
        {/* <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div /> */}
      </div>
    </div>
  )
}

Loading.propTypes = {
  container: PropType.bool
}

export default Loading
