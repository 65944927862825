import React from 'react'
import { useHistory } from 'react-router-dom'
import { useConfig } from '@hooks/useConfig'
import useMixPanel from '@hooks/useMixPanel'

import PropType from 'prop-types'

import './style.scss'

const Horizontal = ({ navbar }) => {
  const config = useConfig()
  const { links, text_color: txColor } = navbar
  const { push } = useHistory()
  const { track } = useMixPanel(config)

  const handleClick = (link) => {
    if (link === '/pedir'){
      track('HOME_BOTON_PEDIR', {
        link: link,
        isMobile: false,
        event: 'click'
      })
    }

    if (link) {
      if (link.includes('http') || link.includes('mailto') || link.includes('tel:')) {
        window.open(link)
      } else {
        push(link)
      }
    }
  }

  return (
    <nav>
      <div className="nav-wrapper" style={{ top: '25px' }}>
        <div className="logo"></div>
        <div className="nav-links">
          {links.map((link) => (
            <div
              className="nav-item nav-link"
              key={link.id}
              style={{ color: txColor }}
              onClick={() => handleClick(link.path)}
            >
              <span>{link.title}</span>
            </div>
          ))}
        </div>
      </div>
    </nav>
  )
}

Horizontal.propTypes = {
  navbar: PropType.object
}

export default Horizontal
