import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import useAuth from '@hooks/useAuth'
import { useConfig } from '@hooks/useConfig'
import PropTypes from 'prop-types'
import { MdClose, MdExitToApp, MdMenu } from 'react-icons/md'

import _find from 'lodash/find'
import _get from 'lodash/get'

import MenuItem from '../../../../components/MenuItem'
import './styles.scss'

SidebarMenu.propTypes = {
  categories: PropTypes.array
}

export default function SidebarMenu({ categories }) {
  const { sections } = useConfig()
  const [, dispatch] = useAuth()
  const navbar = _find(sections, { type: 'navbar' })
  const logo = _get(navbar, 'files.0.path')

  const [openMenu, setOpenMenu] = useState(false)

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <div className={openMenu ? 'sidebar-menu sidebar-menu--responsive' : 'sidebar-menu'}>
      <div className="sidebar-menu__toggle">
        <button className="btn btn--none" onClick={handleToggleMenu}>
          {openMenu ? <MdClose size={30} /> : <MdMenu size={30} />}
        </button>
      </div>
      <div className={openMenu ? 'sidebar-menu__wrapper active' : 'sidebar-menu__wrapper'}>
        <div className="sidebar-menu__logo">
          <Link to="/">
            <img alt="Logo" className="img-fluid" src={logo} />
          </Link>
        </div>
        <div className="sidebar-menu__list">
          {categories.map(({ id, title, icon, items }) => {
            return <MenuItem icon={icon} items={items} key={id} title={title} />
          })}
          <div className="sidebar-menu__account">
            <span className="sidebar-menu__link" onClick={() => dispatch.logout()}>
              <MdExitToApp /> Salir
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
