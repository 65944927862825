import React, { useEffect, useState } from 'react'

import useAuth from '@hooks/useAuth'
import { useCheckout } from '@hooks/useCheckout'
import useGoogleMaps from '@hooks/useGoogleMaps'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _merge from 'lodash/merge'
import _size from 'lodash/size'

export default function AddressOptions() {
  const [{ profile }] = useAuth()
  const [checkout, setCheckout] = useCheckout()
  const { getDetail, detail } = useGoogleMaps()

  const [activeOption, setOption] = useState(_get(checkout, 'address', {}))
  const placeId = _get(activeOption, 'place_id', null)

  const { addresses = [] } = profile
  const checkoutPlaceId = _get(checkout, 'address.place_id')
  const additionalInformation = _get(checkout, 'additional_information', '')

  const detailPlaceId = _get(detail, 'place_id')
  const profileAddress = _find(addresses, { place_id: _get(detail, 'place_id') })

  useEffect(() => {
    if (!placeId && addresses.length > 0) {
      setOption(_get(addresses, '0', {}))
    } else if (placeId && _find(addresses, { id: placeId })) {
      setOption(_find(addresses, { id: placeId }))
    }
  }, [placeId, detail, addresses, getDetail, setOption])

  useEffect(() => {
    if (_size(activeOption) > 0) {
      if (detailPlaceId && checkoutPlaceId !== activeOption.place_id) {
        setCheckout({
          field: 'address',
          value: _merge({}, detail, profileAddress, {
            additional_information: additionalInformation
          })
        })
        setCheckout({
          field: 'delivery',
          value: _get(detail, 'delivery', false)
        })
        setCheckout({
          field: 'delivery_fee',
          value: _get(detail, 'store.delivery_fee', 0)
        })
        setCheckout({
          field: 'store_id',
          value: _get(detail, 'store.id', '')
        })
        setCheckout({
          field: 'store_name',
          value: _get(detail, 'store.name', '')
        })
      }
    } else {
      if (_get(checkout, 'address') !== null) {
        setCheckout({
          field: 'address',
          value: null
        })
        setCheckout({
          field: 'delivery',
          value: false
        })
        setCheckout({
          field: 'delivery_fee',
          value: 0
        })
        setCheckout({
          field: 'store_id',
          value: ''
        })
        setCheckout({
          field: 'store_name',
          value: ''
        })
      }
    }
    // eslint-disable-next-line
  }, [checkoutPlaceId, detailPlaceId, activeOption])

  const handleChange = async (id) => {
    if (id !== '') {
      const location = _find(addresses, { id })
      await setOption(location)
      await getDetail(location.place_id)
    }
  }

  return (
    <div className="saved-address">
      <select
        className="custom-select"
        defaultValue={_get(activeOption, 'id')}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
      >
        <option value="" onSelect={() => setOption({})}>
          Selecciona una dirección
        </option>
        {addresses.map((option, index) => (
          <option key={index} value={option.id}>
            {_get(option, 'address')} {_get(option, 'additional_information')},{' '}
            {_get(option, 'secondary_information')}
          </option>
        ))}
      </select>
    </div>
  )
}
