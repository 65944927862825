/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import CartButton from '@components/CartButton'
import CartList from '@components/CartList'
import AddressLocation from '@components/DeliveryOptions/AddressLocation'
import StoreLocation from '@components/DeliveryOptions/StoreLocation'
import NavBar from '@components/Navbar'
import SearchProducts from '@components/SearchProducts'
import Results from '@components/SearchProducts/Results'
import useAuth from '@hooks/useAuth'
import { useCheckout } from '@hooks/useCheckout'
import { useConfig } from '@hooks/useConfig'
import { useStickyCart } from '@hooks/useStickyCart'
import useAxios from 'axios-hooks'
import { MdMenu, MdPerson } from 'react-icons/md'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _has from 'lodash/has'
// import _size from 'lodash/size'

import './styles.scss'

const Header = () => {
  const [checkout, setCheckout] = useCheckout()
  const [toogleCart, setToogleCart] = useStickyCart()
  const [{ data: stores }] = useAxios({ url: '/stores' })
  const { sections } = useConfig()
  const navbar = _find(sections, { type: 'navbar' })
  const [{ profile }, dispatch] = useAuth()

  const [isBrandUser, setIsBrandUser] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [deliveryType, setDeliveryType] = useState('delivery')
  const [productSearched, setProductSearched] = useState('')

  const [toogleNav, setToogleNav] = useState(false)
  const [toogleSearch, setToogleSearch] = useState(false)

  const location = useLocation()

  const btnColor = _get(navbar, 'button_color')
  const btnTextColor = _get(navbar, 'button_text_color')

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 768) {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    setToogleNav(false)
    setToogleCart(false)
    setToogleSearch(false)
  }, [location, setToogleCart])

  useEffect(() => {
    if (_has(profile, 'id')) {
      const brandUser =
        _get(profile, 'role', []).indexOf('admin') !== -1 ||
        _get(profile, 'role', []).indexOf('operador') !== -1 ||
        false
      setIsBrandUser(brandUser)
      setIsLogged(true)
    }

    const typeOrder = checkout.type_order || 'delivery'

    setCheckout({
      field: 'type_order',
      value: typeOrder
    })
    setCheckout({
      field: 'stores',
      value: stores
    })
    setDeliveryType(typeOrder)
  }, [profile, checkout.type_order, stores, setCheckout, setDeliveryType])

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 5) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const handleDeliveryType = (type) => {
    setCheckout({ field: 'type_order', value: type })
    setDeliveryType(type)
  }

  const handleToogleNav = () => {
    setToogleNav(!toogleNav)
    setToogleCart(false)
    setToogleSearch(false)
  }

  if (!navbar) {
    return null
  }

  return (
    <>
      <div className={`navbar ${scrolled ? 'sticky-scrolled' : ''}`}>
        {/* Mobile actions */}
        <div className="brand-mobile">
          <Link className="navbar-brand" to="/">
            <img alt="Logo" src={_get(navbar, 'files.0.path')} />
          </Link>
          <label className="align-self-center btnMenu" onClick={handleToogleNav}>
            <MdMenu className="align-self-center" size={28} />
          </label>
          <div className="d-flex justify-concet-center align-content-center btnCart">
            <div className="navbar-cart">
              <CartButton setToogleCart={setToogleCart} />
            </div>
            {!isLogged && (
              <div className="user_btns">
                <div className="ubtn btn-secondary">
                  <Link className="btn btn-sm" to="/login">
                    <MdPerson className="person-icon" />{' '}
                    <label className="person-text">Iniciar sesión</label>
                  </Link>
                </div>
                <div className="ubtn btn-register">
                  <Link className="btn btn-sm" to="/login?register">
                    Registrate
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Tablet & Desktop actions */}
        <div className="navbar-wrapper-actions alig-self-center mobile-order-1 brand-menu">
          <div className="d-flex justify-content-start align-content-center">
            <label className="align-self-center" id="btnMenu" onClick={handleToogleNav}>
              <MdMenu className="align-self-center" size={28} />
            </label>
            <Link className="navbar-brand" to="/">
              <img alt="Logo" src={_get(navbar, 'files.0.path')} />
            </Link>
          </div>
        </div>
        <div className="d-flex justify-content-start align-content-center alig-self-center mobile-order-2 mobile-order-fullvw">
          <div className="actions_delivery">
            <div className="input_options">
              <div
                aria-checked={deliveryType === 'delivery' || false}
                aria-label="Entrega a domicilio"
                className="input_options__radio"
                role="radio"
                tabIndex="0"
                onClick={() => handleDeliveryType('delivery')}
              >
                Despacho
              </div>
              <div
                aria-checked={deliveryType === 'takeaway' || false}
                aria-label="Recolectar"
                className="input_options__radio"
                role="radio"
                tabIndex="0"
                onClick={() => handleDeliveryType('takeaway')}
              >
                Retirar
              </div>
            </div>
          </div>

          <div className="address_order">
            {deliveryType === 'delivery' ? <AddressLocation /> : <StoreLocation stores={stores} />}
          </div>
        </div>
        <div className="d-flex justify-content-center align-content-center alig-self-center mobile-order-3 mobile-order-fullvw">
          <div className="search_products">
            <SearchProducts
              productSearched={productSearched}
              setProductSearched={setProductSearched}
              setToogleSearch={setToogleSearch}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end align-content-center alig-self-center mobile-order-1 cart-actions">
          <div className="navbar-cart">
            <CartButton setToogleCart={setToogleCart} />
          </div>
          {!isLogged && (
            <div className="user_btns">
              <div className="ubtn btn-secondary">
                <Link className="btn btn-sm" to="/login">
                  <MdPerson className="person-icon" />{' '}
                  <label className="person-text">Iniciar sesión</label>
                </Link>
              </div>
              <div className="ubtn btn-register">
                <Link className="btn btn-sm" to="/login?register">
                  Registrate
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <input checked={toogleNav} className="nav-toggle" id="nav-toggle" type="checkbox" />
      <NavBar
        dispatch={dispatch}
        isBrandUser={isBrandUser}
        isLogged={isLogged}
        profile={profile}
        setIsLogged={setIsLogged}
        setToogleNav={setToogleNav}
      />

      <input checked={toogleSearch} className="search-toggle" id="search-toggle" type="checkbox" />
      <Results
        productSearched={productSearched}
        setProductSearched={setProductSearched}
        setToogleSearch={setToogleSearch}
        stores={stores}
      />

      <input checked={toogleCart} className="cart-toggle" id="cart-toggle" type="checkbox" />
      <CartList setToogleCart={setToogleCart} />
      <style>
        {`
        .button-bg, .button-bg > button  {
          color: ${btnTextColor};
          background-color: ${btnColor};
        }
        
        .user_btns > .btn-register {
          background-color: ${btnColor};
        }

        .btn-register > a {
          color: ${btnTextColor}!important;
        }
        `}
      </style>
    </>
  )
}

export default Header
