
import React from 'react'

import Button from '@components/Button/Button'
import Tabs from '@components/Tabs'
import { useCheckout } from '@hooks/useCheckout'
import { useConfig } from '@hooks/useConfig'
import { useRouter } from '@hooks/useRouter'
import { StoresProvider } from '@hooks/useStores'
import isElectron from '@utils/isElectron'
import PropTypes from 'prop-types'

import SidebarMenu from './SidebarMenu'
import './styles.scss'

const categories = [
  {
    id: 1,
    title: 'Nuevos pedidos',
    kind: 'new',
    items: [
      { title: 'Nuevos', path: '/salepoint/orders/new', icon: <i className='fas fa-rss'></i> }
    ]
  },
  {
    id: 2,
    title: 'Pedidos activos',
    kind: 'active',
    items: [
      { title: 'Activos', path: '/salepoint/orders/actived', icon: <i className='fas fa-th-large'></i> }
    ]
  },
  {
    id: 3,
    title: 'Pedidos pasados',
    kind: 'past',
    items: [
      { title: 'Pasados', path: '/salepoint/orders/past', icon: <i className='fas fa-history'></i> }
    ]
  },
  {
    id: 4,
    title: 'Pedidos programados',
    kind: 'scheduled',
    items: [
      { title: 'Programados', path: '/salepoint/orders/programmed', icon: <i className='far fa-clock'></i> }
    ]
  },
  {
    id: 5,
    title: 'Fechas Especiales',
    kind: 'scheduled_days',
    items: [
      { title: 'Fechas Especiales', path: '/salepoint/orders/special_dates', icon: <i className='fas fa-calendar-day'></i> }
    ]
  },
  {
    id: 6,
    title: 'Agotar productos',
    items: [
      { title: 'Agotar', path: '/salepoint/outstock', icon: <i className='fas fa-exclamation-triangle'></i> }
    ]
  },
  {
    id: 7,
    title: 'Configuracion',
    items: [
      { title: 'Locales', path: '/salepoint/config/stores', icon: <i className='fas fa-cog'></i> }
    ]
  }
]

Layout.propTypes = {
  navs: PropTypes.array,
  excludedPaths: PropTypes.array,
  children: PropTypes.any
}

export default function Layout ({ navs, excludedPaths, children }) {
  const { location } = useRouter()
  const { website } = useConfig()

  const [, setCheckout] = useCheckout()
  setCheckout({ action: 'reset' })

  const isExcluded = excludedPaths.indexOf(location.pathname) !== -1
  const downloadPos = () => {
    // eslint-disable-next-line
    window.open(`https://delivery-gourmet-sites-app-files.s3.amazonaws.com/pos/oficiales/${website}-pos.exe`)
  }
  return (
    <main>
      <StoresProvider>
        {!isElectron() && <div className='dlvry-desktop-app'>
          <Button
            color='white'
            disabled={false}
            icon='fas fa-desktop'
            value='Descargar aplicación de escritorio'
            onClick={() => downloadPos()}
          />
        </div>}
        <SidebarMenu categories={categories} />
        <div className='main-wrapper_salepoint'>
          {!isExcluded && navs.length > 0 && <Tabs tabs={navs} />}
          {children}
        </div>
      </StoresProvider>
    </main>
  )
}
