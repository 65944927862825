import React, { createContext, useEffect } from 'react'

import useAuth from '@hooks/useAuth'
import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'

export const ModifiersContext = createContext({ modifiers: [], loadingModifiers: false, executeGetModifiers: null })

const ModifiersContextProvider = ({ children }) => {
  const [{ accessToken }] = useAuth()

  const [{ data: modifiers, loading: loadingModifiers }, executeGetModifiers] = useAxios({ url: 'dashboards/optionals/', headers: { Authorization: `Bearer ${accessToken}` } }, { manual: true, useCache: false })

  useEffect(() => {
    if (accessToken) {
      executeGetModifiers()
    }
  }, [executeGetModifiers, accessToken])

  return (
    <ModifiersContext.Provider value={{ modifiers, loadingModifiers, executeGetModifiers }}>
      {children}
    </ModifiersContext.Provider>
  )
}

ModifiersContextProvider.propTypes = {
  children: PropTypes.any
}

export default ModifiersContextProvider
