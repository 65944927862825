import React, { useEffect, useState } from 'react'
import { useCart } from '@hooks/useCart'
import { useCheckout } from '@hooks/useCheckout'
import { useGiftcard } from '@hooks/useGiftcard'
import useApi from '@hooks/useApi'

import miles from '@utils/miles'

import _get from 'lodash/get'
import _size from 'lodash/size'

import './styles.scss'

const API_URL = '/dashboards/donations/'
export default function Donations() {
  const [checkout, setCheckout] = useCheckout()
  const [cart] = useCart()
  const [giftcard] = useGiftcard()
  
  const { donation_amount: initialDonation = 0, payment_type: paymentType = ''} = checkout
  const [donation, setDonation] = useState(parseInt(initialDonation))
  
  // eslint-disable-next-line
  // const [donationId, setDonationId] = useState('')

  const typeOrder = _get(checkout, 'type_order')
  // let donationAmounts = [0, 500]
  let donationAmounts = []

  const [{ data }, execute] = useApi({ url: `${API_URL}` })

  useEffect(() => {
    execute({
      url: `/dashboards/donations`
    })

    setCheckout({ field: 'donation_amount', value: donation })
    setCheckout({ field: 'donation_id', value: data.id })

    // eslint-disable-next-line
  }, [donation])

  useEffect(() => {
    if (typeOrder === 'takeaway') {
      setDonation(0)
    }
    if (paymentType !== 'webpayplus') {
      setDonation(0)
    }
  }, [typeOrder, paymentType])

  if (_size(cart) === 0 && _size(giftcard) > 0) {
    return null
  }

  if(data.amounts){
    donationAmounts = data.amounts
  }

  if (donationAmounts.length > 0 ){
    return typeOrder !== 'takeaway' && (paymentType === 'webpayplus' || paymentType === 'sodexo') ? (
      <div className="donations">
        <span>{`${data.title}` }</span>
        <div className="donations__amount">
          {
            donationAmounts.map((donationValue, index) => {
              const className = parseInt(donationValue) === parseInt(donation) ? 'donation active' : 'donation'
              return (
                <span className={className} key={index} onClick={() => setDonation(donationValue)}>
                  ${miles(donationValue)}
                </span>
              )
            })
          }
        </div>
      </div>
    ) : null
  }else{
    return null
  }
}
