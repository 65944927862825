/* eslint-disable complexity */
import React, { useState, useEffect, useMemo } from 'react'
import { useToasts } from 'react-toast-notifications'

import AdditionalMaps from '@components/AdditionalMaps'
import AddressOptions from '@components/AddressOptions'
import SelectMaps from '@components/SelectMaps'
import useAuth from '@hooks/useAuth'
import { useCheckout } from '@hooks/useCheckout'
import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'
import { FaCheckDouble, FaMapMarkerAlt } from 'react-icons/fa'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _size from 'lodash/size'

import './customer.scss'

CustomerAddress.propTypes = {
  setAddress: PropTypes.func,
  setAddressSelected: PropTypes.func,
  setShow: PropTypes.func
}

export default function CustomerAddress({ setAddress, setAddressSelected, setShow }) {
  const { addToast } = useToasts()
  const [{ data: stores }] = useAxios({ url: '/stores' })
  const [checkout, setCheckout] = useCheckout()
  const { delivery, store_id: storeId, address } = checkout

  const [{ profile }] = useAuth()
  const store = _find(stores, { id: storeId })
  const isOpen = _get(store, 'is_open', false)
  const placeId = _get(address, 'place_id', '')

  const { addresses = [] } = profile

  const [activeOption, setOption] = useState('select')
  // const [activeOption, setOption] = useState(_get(checkout, 'orderOption', 'select'))

  const addressOk = !!delivery
  const storeName = _get(store, 'name', '')

  useEffect(() => {
    if (activeOption !== checkout.orderOption) {
      setCheckout({
        field: 'orderOption',
        value: activeOption
      })
    }
  }, [activeOption, checkout.orderOption, setCheckout])

  useEffect(() => {
    if (_has(profile, 'id') && _size(addresses) > 0 && activeOption === 'select') {
      setOption('select')
    }
  }, [addresses, profile, activeOption])

  useEffect(() => {
    if (isOpen !== checkout.store_open) {
      setCheckout({
        field: 'store_open',
        value: isOpen
      })
    }
  }, [isOpen, checkout.store_open, setCheckout])

  const handleOkAddress = () => {
    const { address } = checkout
    if (address) {
      const { delivery } = address
      if (delivery && address.address) {
        setAddressSelected(true)
        setAddress(address.address)
        setShow(false)
      } else {
        setAddressSelected(false)
        setAddress('')
        addToast('La dirección ingresada no tie factibilidad de delivery', {
          appearance: 'warning'
        })
      }
    } else {
      setAddressSelected(false)
      setAddress('')
      addToast('La dirección ingresada no tie factibilidad de delivery', { appearance: 'warning' })
    }
  }

  const handleOptionDelivery = (option) => {
    setOption(option)
    setCheckout({
      field: 'orderOption',
      value: option
    })
  }

  const renderSearchAddress = () => {
    return (
      <>
        <div className="form-group">
          <h6>Dirección de entrega</h6>
          <SelectMaps isHome />
        </div>
        <div className="form-group">
          <h6>Dpto, Casa, Etc</h6>
          <AdditionalMaps outline />
        </div>
      </>
    )
  }

  const renderOwnDirections = () => {
    return (
      <div className="customer_box__selection">
        <div className="customer_box__selection__options">
          <div
            className={`option ${activeOption === 'search' && 'option__active'}`}
            onClick={() => handleOptionDelivery('search')}
          >
            Cambiar dirección
          </div>
          <div
            className={`option ${activeOption === 'select' && 'option__active'}`}
            onClick={() => handleOptionDelivery('select')}
          >
            Usar dirección anterior
          </div>
        </div>
        {activeOption === 'search' && renderSearchAddress()}
        {activeOption === 'select' && <AddressOptions />}
      </div>
    )
  }

  const renderDelivery = useMemo(() => {
    if (placeId === 'invalid') {
      return <p className="text-outline">Lo sentimos, no llegamos a tu dirección</p>
    }

    return (
      <>
        {placeId && delivery && (
          <p className="text-outline">
            <FaCheckDouble className="icon green-icon" />
            Envío a domicilio disponible
          </p>
        )}
        {placeId && !delivery && (
          <p className="text-outline">Lo sentimos, no llegamos a tu dirección</p>
        )}
        {placeId && delivery && storeName && (
          <p className="text-outline mb-3">
            <FaMapMarkerAlt className="icon" />
            <strong>Tu pedido será enviado desde {storeName}</strong>
          </p>
        )}
      </>
    )
  }, [placeId, delivery, storeName])

  const renderOrderButton = () => {
    return (
      <button
        className={`btn btn--outline ${addressOk ? 'btn-block' : 'btn-disabled'}`}
        disabled={!addressOk}
        onClick={() => handleOkAddress()}
      >
        Listo
      </button>
    )
  }

  return (
    <>
      <div className="customer_box">
        <h3 className="box_title">Entregar en</h3>
        {_size(_get(profile, 'addresses', [])) > 0 ? renderOwnDirections() : renderSearchAddress()}
        {renderDelivery}
        {renderOrderButton()}
      </div>
    </>
  )
}
