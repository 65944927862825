import React from 'react'
import { NavLink } from 'react-router-dom'

import { useRouter } from '@hooks/useRouter'
import PropTypes from 'prop-types'

import _each from 'lodash/each'

import Placeholder from './../../assets/images/placeholder.png'
import './styles.scss'

const ProductSidebar = ({ products }) => {
  const { query } = useRouter()
  return (
    <>
      {products.map(({ id, name, file, categories }, index) => {
        let path = '/admin/products/information/:productId'
        _each(query, (value, key) => {
          path = path.replace(`:${key}`, id)
        })
        return (
          <NavLink className="product-sidebar" key={index} to={path}>
            <div className="product-sidebar__img">
              {file ? <img alt={name} src={file.path} /> : <img alt={name} src={Placeholder} />}
            </div>
            <div className="product-sidebar__info">
              <h5>{name}</h5>
              <div className="product-sidebar__tags">
                {categories.map(({ name }, index) => {
                  return (
                    <span className="badge badge--tag" key={index}>
                      {name}
                    </span>
                  )
                })}
              </div>
            </div>
          </NavLink>
        )
      })}
    </>
  )
}

ProductSidebar.propTypes = {
  products: PropTypes.array
}

export default ProductSidebar
