import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as ModifiersLayout } from '@pages/Admin/Modifiers/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/modifiers/:action?/:modifierId?',
  guard: true,
  layout: ModifiersLayout,
  navs: [],
  routes: [
    {
      exact: true,
      path: '/admin/modifiers',
      component: lazy(() => retry(() => import('@pages/Admin/Modifiers')))
    },
    {
      exact: true,
      path: '/admin/modifiers/detail/:modifierId',
      component: lazy(() => retry(() => import('@pages/Admin/Modifiers/Detail')))
    },
    {
      exact: true,
      path: '/admin/modifiers/configuration/',
      component: lazy(() => retry(() => import('@pages/Admin/Modifiers/ConfigurationNew')))
    },
    {
      exact: true,
      path: '/admin/modifiers/configuration/:modifierId',
      component: lazy(() => retry(() => import('@pages/Admin/Modifiers/Configuration')))
    },
    {
      exact: true,
      path: '/admin/modifiers/detail/:modifierId/item/:itemId',
      component: lazy(() => retry(() => import('@pages/Admin/Modifiers/Option')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
