import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

import { useCheckout } from '@hooks/useCheckout'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'

import _get from 'lodash/get'

import CustomerAddress from './Modal/CustomerAddress'

import './style.scss'

export default function AddressLocation() {
  const [checkout] = useCheckout()
  const [addressSelected, setAddressSelected] = useState(!!_get(checkout.address, 'address'))
  const [address, setAddress] = useState('')
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleClickAddress = () => {
    setShow(true)
  }

  useEffect(() => {
    if (checkout.address) {
      setAddress(_get(checkout.address, 'address', ''))
      setAddressSelected(!!_get(checkout.address, 'address'))
    }
  }, [checkout.address, setAddress, setAddressSelected])

  return (
    <>
      <div className="address_box" onClick={handleClickAddress}>
        <label htmlFor="address">
          <FaMapMarkerAlt className="mr-2" />
          {!addressSelected ? <>Ingresa tu dirección</> : <>{address}</>}
        </label>
      </div>

      <Modal centered show={show} size="md" onHide={handleClose}>
        <button className="modal-close" onClick={handleClose}>
          <MdClose size="20" />
        </button>
        <CustomerAddress
          setAddress={setAddress}
          setAddressSelected={setAddressSelected}
          setShow={setShow}
        />
      </Modal>
    </>
  )
}
