/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import CartButton from '@components/CartButton'
import DiscountButton from '@components/DiscountButton'
import { useCart } from '@hooks/useCart'
import { useCheckout } from '@hooks/useCheckout'
import { useGiftcard } from '@hooks/useGiftcard'
import PropTypes from 'prop-types'
import { MdClose } from 'react-icons/md'

import _get from 'lodash/get'
import _size from 'lodash/size'

import Cart from './Cart'
import './style.scss'

CartList.propTypes = {
  setToogleCart: PropTypes.func
}

export default function CartList({ setToogleCart }) {
  const [checkout] = useCheckout()
  const [cart] = useCart()
  const [giftcard] = useGiftcard()

  const type_order = _get(checkout, 'type_order', null)

  const [hasCart, setHasCart] = useState(false)
  const [pickupName, setPickupName] = useState('')
  const [hasAddress, setHasAddress] = useState(false)

  useEffect(() => {
    if (cart.length > 0 || giftcard.length > 0) {
      setHasCart(true)
    }
  }, [cart, giftcard, setHasCart])

  useEffect(() => {
    if (type_order) {
      const TARGET_NAME = {
        takeaway: {
          name: checkout.store_name
        },
        delivery: {
          name: `${checkout?.address?.address} ${
            checkout?.address?.additional_information &&
            `- ${checkout?.address?.additional_information}`
          }, ${checkout?.address?.secondary_information}`
        }
      }[type_order]

      if (!checkout.store_name && !checkout?.address?.address) {
        setHasAddress(false)
      } else {
        setHasAddress(true)
        setPickupName(TARGET_NAME.name)
      }
    }
  }, [type_order, checkout])

  const handleClose = () => {
    setToogleCart(false)
  }

  return (
    <>
      <div className="cart-box no-visible">
        <div className="cart-list">
          <div className="cart-list--info">
            <label className="cart-list--info--close" onClick={handleClose}>
              <MdClose className="close-icon" size={20} />
            </label>
            <CartButton />
          </div>
          <h3 className="cart-list--title">Mi pedido</h3>
          {type_order && hasCart && hasAddress && (
            <h3 className="cart-list--subtitle">
              {type_order === 'takeaway' ? 'Retirar' : 'Entrega'} en {pickupName}
            </h3>
          )}

          <hr />

          <Cart />
          {_size(cart) > 0 && <DiscountButton />}
        </div>
      </div>
    </>
  )
}
