
export default function reducer (state, { profile, accessToken }) {
  if (profile || accessToken) {
    return {
      profile: Object.assign({}, state.profile, profile), accessToken: accessToken || state.accessToken
    }
  }

  return state
}
