import axios from 'axios'
import { configure } from 'axios-hooks'
import cachios from 'cachios'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const accessToken = window.localStorage.getItem('accessToken') === 'undefined' ? '' : JSON.parse(window.localStorage.getItem('accessToken'))

const instance = accessToken
  ? axios.create({ headers: { Authorization: `Bearer ${accessToken}` }})
  : axios.create()
configure({ axios: instance })

const cachiosInstance = cachios.create(instance)

export { cachiosInstance }

export default instance
