import React from 'react'

import capitalize from '@utils/capitalize'
import PropTypes from 'prop-types'

import './button.scss'

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  icon: PropTypes.string
}
export default function Button({
  value = 'click me',
  color = 'white',
  disabled = false,
  style = {},
  onClick = () => null,
  icon = undefined
}) {
  return (
    <button
      className={`dlvry-button btn btn--${color}`}
      disabled={disabled}
      style={style}
      type="button"
      onClick={onClick}
    >
      {icon !== undefined ? <i className={`${icon}`} /> : null}
      {capitalize(value)}
    </button>
  )
}
