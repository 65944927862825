import { useEffect, useState } from 'react'

import { cachiosInstance } from '@services/axios'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'

var memoryCache = []

export default function useGoogleMaps() {
  const [address, setAddress] = useState('')
  const [options, setOptions] = useState([])
  const [loading] = useState(false)
  const [delivery, setDelivery] = useState()
  const [detail, setDetail] = useState(null)
  const [places, setPlaces] = useState(null)

  const findPlaces = _debounce(async (str) => {
    if (!_get(memoryCache, str, false)) {
      return cachiosInstance
        .get(`/search_places?filter=${String(str).toLowerCase()}`)
        .then((resp) => {
          memoryCache[str] = resp.data
          setPlaces(resp.data)
        })
    } else {
      setPlaces(_get(memoryCache, str))
    }
  }, 250)

  const getDetail = _debounce((placeId) => {
    if (!placeId) {
      return setDetail(null)
    }
    if (!_get(memoryCache, placeId, false)) {
      cachiosInstance.get(`/search_places/${placeId}/details`).then((resp) => {
        if (_get(resp.data, 'place_id', false)) {
          memoryCache[placeId] = resp.data

          if (resp.data.delivery) {
            setDetail(resp.data)
          } else {
            setDetail({
              place_id: 'invalid'
            })
          }
        } else {
          setDetail({
            place_id: 'invalid'
          })
        }
      })
    } else {
      setDetail(_get(memoryCache, placeId))
    }
  }, 250)

  useEffect(() => {
    setDelivery(_get(delivery, 'store_name', null))
  }, [delivery])

  useEffect(() => {
    if (_has(detail, 'address')) {
      setAddress(`${_get(detail, 'address')}, ${_get(detail, 'secondary_information')}`)
    } else {
      setAddress('')
    }
  }, [detail])

  useEffect(() => {
    if (_isArray(places)) {
      setOptions(
        places.map((dat) => {
          return {
            value: _get(dat, 'place_id'),
            label: `${_get(dat, 'address')}, ${_get(dat, 'secondary_information')}`
          }
        })
      )
    }
  }, [places, setOptions])

  return { loading, address, findPlaces, getDetail, places, options, detail, delivery }
}
