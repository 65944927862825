import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/stores/:action?/:storeId?',
  guard: true,
  layout: AdminLayout,
  navs: [
    { name: 'Información', path: '/admin/stores/information/:storeId' },
    { name: 'Horario', path: '/admin/stores/schedule/:storeId' },
    { name: 'Reservas', path: '/admin/stores/reservation/:storeId' },
    { name: 'Delivery', path: '/admin/stores/delivery/:storeId' },
    { name: 'Soporte', path: '/admin/stores/support/:storeId' },
    { name: 'Eliminar', path: '/admin/stores/delete/:storeId' }
  ],
  excludedPaths: [
    '/admin/stores',
    '/admin/stores/create'
  ],
  routes: [
    {
      exact: true,
      path: '/admin/stores',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/List')))
    },
    {
      exact: true,
      path: '/admin/stores/create',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Create')))
    },
    {
      exact: true,
      path: '/admin/stores/information/:storeId',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Information')))
    },
    {
      exact: true,
      path: '/admin/stores/reservation/:storeId',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Reservation')))
    },
    {
      exact: true,
      path: '/admin/stores/schedule/:storeId',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Schedule')))
    },
    {
      exact: true,
      path: '/admin/stores/delivery/:storeId',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Delivery')))
    },
    {
      exact: true,
      path: '/admin/stores/support/:storeId',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Support')))
    },
    {
      exact: true,
      path: '/admin/stores/delete/:storeId',
      component: lazy(() => retry(() => import('@pages/Admin/Stores/Delete')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
