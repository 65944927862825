import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as AdminLayout } from '@pages/Admin/Layout'
import retry from '@utils/retry'

export default {
  path: '/admin/mailings/:action?/:mailingId?',
  guard: true,
  credentials: ['admin', 'operador', 'marketing'],
  layout: AdminLayout,
  navs: [],
  routes: [
    {
      exact: true,
      path: '/admin/mailings',
      component: lazy(() => retry(() => import('@pages/Admin/Mailings/List')))
    },
    {
      exact: true,
      path: '/admin/mailings/detail',
      component: lazy(() => retry(() => import('@pages/Admin/Mailings/Detail')))
    },
    {
      exact: true,
      path: '/admin/mailings/detail/:mailingId?',
      component: lazy(() => retry(() => import('@pages/Admin/Mailings/Detail')))
    },
    {
      exact: true,
      path: '/admin/mailings/reports',
      component: lazy(() => retry(() => import('@pages/Admin/Mailings/Reports')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
