import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import PropTypes from 'prop-types'
import { MdSearch } from 'react-icons/md'
import './styles.scss'

const SearchSidebar = ({ hasSelect, children, options, setSelectedCategory, setQuery, query, createLink }) => {
  const handleChange = (category) => {
    if (category !== null) {
      setSelectedCategory(category.value)
    } else {
      setSelectedCategory('')
    }
  }
  const handleQuery = (e) => {
    setQuery(e.target.value)
  }

  return (
    <div className="search-sidebar">
      <div className="search-sidebar__top-box">
        <div className="search-sidebar__filter">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <MdSearch />
              </span>
            </div>
            <input className="form-control" placeholder="Filtrar"
              type="text"
              value={query}
              onChange={(e) => handleQuery(e)}
            />
          </div>
        </div>
        {hasSelect && (
          <div className="search-sidebar__select">
            <Select isClearable options={options}
              placeholder="Filtrar por categoría" onChange={handleChange} />
          </div>
        )}
      </div>
      <div className="search-sidebar__list">{children}</div>
      {createLink &&
        <div className="search-sidebar__create">
          <Link className="btn btn-primary w-100" to={createLink}>Crear</Link>
        </div>
      }
    </div>
  )
}

SearchSidebar.propTypes = {
  children: PropTypes.any,
  hasSelect: PropTypes.bool,
  options: PropTypes.array,
  setSelectedCategory: PropTypes.func,
  setQuery: PropTypes.func,
  query: PropTypes.string,
  createLink: PropTypes.string
}

export default SearchSidebar
