import React from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

import Header from '@pages/Site/Layout/Header'
import PropType from 'prop-types'

import Horizontal from './Horizontal'
import Vertical from './Vertical'

const SidebarNavbar = ({ navbar, useNavbar }) => {
  const location = useLocation()
  if (isMobile && location.pathname === '/') {
    return (
      <>
        <Vertical navbar={navbar} />
        {useNavbar && <Header />}
      </>
    )
  }

  if (location.pathname === '/') {
    if (useNavbar) {
      return <Header />
    } else {
      return <Horizontal navbar={navbar} />
    }
  }

  if (useNavbar) {
    return <Header />
  }

  return null
}

SidebarNavbar.propTypes = {
  navbar: PropType.object,
  useNavbar: PropType.bool
}

export default SidebarNavbar