import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { default as SalepointLayout } from '@pages/Salepoint/Layout'
import retry from '@utils/retry'

export default {
  path: '/salepoint/outstock',
  guard: true,
  layout: SalepointLayout,
  navs: [],
  routes: [
    {
      exact: false,
      path: '/salepoint/outstock',
      component: lazy(() => retry(() => import('@pages/Salepoint/Outstock/Outstock')))
    },
    {
      component: <Redirect to={'/404'} />
    }
  ]
}
