/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'

import Spinner from '@components/Spinner'
import useAuth from '@hooks/useAuth'
import { useCart } from '@hooks/useCart'
import { useCheckout } from '@hooks/useCheckout'
import { useConfig } from '@hooks/useConfig'
import useMixPanel from '@hooks/useMixPanel'
import defaultUseAxios from 'axios-hooks'

import _get from 'lodash/get'
import _size from 'lodash/size'

export default function DiscountButton() {
  const config = useConfig()
  const { track } = useMixPanel(config)
  const { addToast } = useToasts()
  const [{ profile }] = useAuth()
  const [cart] = useCart()
  const [checkout, setCheckout] = useCheckout()
  const [dismiss, setDismiss] = useState(false)
  const [coupon, setCoupon] = useState({
    coupon: {
      id: _get(checkout, 'coupon_id'),
      name: _get(checkout, 'coupon_name'),
      code: _get(checkout, 'coupon_code')
    },
    value: _get(checkout, 'coupon_discount')
  })

  const couponId = _get(coupon, 'coupon.id')
  const couponDiscount = _get(coupon, 'value')

  const [showDiscount, setShowDiscount] = useState(false)
  const [discountValue, setDiscountValue] = useState(
    String(_get(checkout, 'coupon_code', '')).toUpperCase()
  )

  const [{ data, loading, error }, execute] = defaultUseAxios(
    {
      url: '/coupon/validate',
      method: 'POST'
    },
    {
      manual: true,
      useCache: false
    }
  )

  const [foundCoupon, setFoundCoupon] = useState({})

  useEffect(() => {
    setFoundCoupon(data)
  }, [data])

  const handleDismiss = () => {
    setShowDiscount(false)
    setDismiss(false)
    setCheckout({ action: 'removeCoupon' })
    setCoupon({})
    setDiscountValue('')
    setFoundCoupon({})
  }

  const validateSendDiscount = () => {
    const { store_id, address, type_order } = checkout

    if (type_order === 'delivery' && address.place_id && address.place_id !== '') {
      return true
    }

    if (type_order === 'takeaway' && store_id && store_id !== '') {
      return true
    }

    return false
  }

  const sendDiscount = () => {
    if (validateSendDiscount()) {
      track('PEDIR_CUPON', {
        tienda: _get(checkout, 'store_name'),
        cupon: String(discountValue).toLowerCase(),
        products: cart,
        event: 'click'
      })

      execute({
        data: {
          store_id: _get(checkout, 'store_id'),
          type_order: _get(checkout, 'type_order'),
          subtotal: _get(checkout, 'sub_total'),
          code: String(discountValue).toLowerCase(),
          products: cart
        }
      })
    } else {
      callToastError(
        'Antes de validar el cupón, debe ingresar una dirección de entrega o sucursal de retiro'
      )
    }
  }

  useEffect(() => {
    if (_size(cart) > 0 && discountValue) {
      sendDiscount()
    }
    if (_size(cart) === 0) {
      handleDismiss()
    }
    // eslint-disable-next-line
  }, [cart])

  useEffect(() => {
    if (error) {
      const status = _get(error, 'response.status')
      const errorMsg = _get(error, 'response.data.message')
      if (status === 400) {
        callToastError(errorMsg)
        handleDismiss()
      }
      if (status === 401) {
        callToastError(
          'Por favor regístrate o ingresa a tu cuenta para poder utilizar un cupón de descuento'
        )
      }
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (discountValue) {
      setDismiss(!!discountValue)
    }
    if (_size(foundCoupon) > 0 && discountValue) {
      setCoupon({
        coupon: {
          ...foundCoupon.coupon,
          code: String(discountValue).toLowerCase()
        },
        value: foundCoupon.value
      })
    }
    // eslint-disable-next-line
  }, [foundCoupon, discountValue])

  useEffect(() => {
    setCheckout({ field: 'coupon_id', value: _get(coupon, 'coupon.id') })
    setCheckout({ field: 'coupon_name', value: _get(coupon, 'coupon.name') })
    setCheckout({ field: 'coupon_code', value: _get(coupon, 'coupon.code') })
    setCheckout({ field: 'coupon_discount', value: _get(coupon, 'value') })
    // eslint-disable-next-line
  }, [couponId, couponDiscount])

  const openDiscount = () => {
    if (_size(profile) === 0) {
      callToastError(
        'Por favor regístrate o ingresa a tu cuenta para poder utilizar un cupón de descuento'
      )
    } else {
      track('PEDIR_AGREGAR_CUPON', {
        event: 'click'
      })
      setShowDiscount(true)
    }
  }

  const callToastError = (message) => {
    addToast(message, { appearance: 'error' })
  }

  const askForDiscountCoupon = () => {
    return (
      <div className="text-center">
        <button className="btn btn--none btn--line-color" onClick={() => openDiscount()}>
          Agregar un codigo de descuento
        </button>
      </div>
    )
  }

  const dismissCoupon = () => {
    return (
      <div className="mt-2">
        <button
          className="btn btn--none btn--line-color"
          type="button"
          onClick={() => handleDismiss()}
        >
          Descartar cupón
        </button>
      </div>
    )
  }

  const fillDiscountCoupon = () => {
    return (
      <>
        <label>¿Tienes un cupón de descuento?</label>
        <div className="input-group mb-5">
          <input
            className="form-control"
            placeholder="Código de descuento"
            style={{ borderLeftColor: '#ced4da' }}
            value={discountValue}
            onChange={(e) => setDiscountValue(String(e.target.value.toUpperCase()))}
          />
          <div className="input-group-append">
            {loading && (
              <button className="btn btn--color">
                <Spinner />
              </button>
            )}
            {!loading && !dismiss && (
              <button className="btn btn--color" type="button" onClick={() => handleDismiss()}>
                No usar cupón
              </button>
            )}
            {!loading && dismiss && (
              <button className="btn btn--color" type="button" onClick={() => sendDiscount()}>
                Usar cupón
              </button>
            )}
          </div>
        </div>
      </>
    )
  }

  if (_get(coupon, 'coupon.id', false)) {
    return dismissCoupon()
  } else {
    return showDiscount ? fillDiscountCoupon() : askForDiscountCoupon()
  }
}
