import { useRGA4 } from '@keg-hub/rga4'

import _size from 'lodash/size'

const formatData = (data) => {
    const items = []
    let value = 0

    if (_size(data) > 0) {
        data.forEach((element, index) => {
            const price = element.discount > 0 ? element.discount : element.price
            const discount = element.discount > 0 ? element.price - element.discount : 0
            value += (price * (element?.quantity || 1)) // valor total
            items.push({
                item_id: element.id,
                item_name: element.name,
                currency: "CLP",
                discount: discount,
                index: _size(items),
                item_variant: 'product',
                price: price,
                quantity: element.quantity || 1
            })

            // optionals
            if (_size(element.optionals) > 0) {
                element.optionals.forEach((optional, op_index) => {
                    optional.items.forEach(((item, key) => {
                        value += (item?.price || 0) // valor total
                        items.push({
                            item_id: item.item_id,
                            item_name: item.name,
                            currency: "CLP",
                            index: _size(items),
                            item_list_id: optional.id,
                            item_list_name: optional.name,
                            item_variant: "optional",
                            price: item.price,
                        })
                    }))
                })
            }
        });

        return {
            items: items,
            value: value
        }
    } else {
        return {
            items: [],
            value: value
        }
    }
}

const useGoogleAnalytics = () => {
    const rga4 = useRGA4()

    const actions = {
        // add_payment_info
        addPaymentInfo: (data) => {
            rga4.event('add_payment_info', {
                event_label: 'Medio de Pago',
                event_category: 'ecommerce',
                currency: "CLP",
                value: 0,
                payment_type: data.label,
                items: []
            })
        },
        // add_shipping_info
        addShippingInfo: (data) => {
            rga4.event('add_shipping_info', {
                event_label: 'Medio de Envio',
                event_category: 'ecommerce',
                currency: "CLP",
                value: data.deliveryFee,
                shipping_tier: data.text,
                items: []
            })
        },
        // add_to_cart
        addToCart: (data) => {
            const newData = formatData([data])

            rga4.event('add_to_cart', {
                event_label: 'Agregar carrito',
                event_category: 'ecommerce',
                currency: "CLP",
                value: newData.value,
                items: newData.items
            })
        },
        // begin_checkout
        beginCheckout: (data) => {
            const newData = formatData(data.products)

            // evento con parametros personalizados
            rga4.event('begin_checkout', {
                event_label: 'Inicio de confirmación de la compra',
                event_category: 'ecommerce',
                currency: "CLP",
                value: newData.value + (data?.delivery_fee || 0) - (data.coupon_discount || 0),
                coupon: data.coupon_name || '',
                store_id: data.store_id,
                store_name: data.store_name,
                items: newData.items
            })
        },
        // generate_lead
        generateLead: () => {
            console.log('Evento generate_lead')
        },
        // purchase
        purchase: (data) => {
            const items = []

            data.details.forEach((element, index) => {
                let price = 0
                let discount = 0

                if (element.quantity > 1) {
                    price = element.sub_total / element.quantity
                    discount = element.discount_normal / element.quantity
                } else {
                    price = element.total
                    discount = element.discount_normal
                }

                items.push({
                    item_id: element.id,
                    item_name: element.name,
                    currency: "CLP",
                    discount: discount,
                    index: _size(items),
                    item_variant: element.type,
                    price: price,
                    quantity: element.quantity
                })

                // optionals
                if (_size(element.optionals) > 0) {
                    element.optionals.forEach((optional, op_index) => {
                        optional.items.forEach(((item, key) => {
                            items.push({
                                item_id: item.id,
                                item_name: item.title,
                                currency: "CLP",
                                index: _size(items),
                                item_list_id: optional.id,
                                item_list_name: optional.title,
                                item_variant: "optional",
                                price: item.price,
                            })
                        }))
                    })
                }
            });

            // evento con parametros personalizados
            rga4.event('purchase', {
                event_label: 'Nueva Compra',
                event_category: 'ecommerce',
                transaction_id: data.id,
                shipping: data.delivery_fee,
                currency: "CLP",
                value: data.total,
                coupon: data?.coupon?.name || '',
                store_id: data.store.id,
                store_name: data.store.name,
                items: items
            })
        },
        // remove_from_cart
        removeFromCart: (data) => {
            const newData = formatData([data])

            rga4.event('remove_from_cart', {
                event_label: 'Eliminar del carrito',
                event_category: 'ecommerce',
                currency: "CLP",
                value: newData.value,
                items: newData.items
            })
        },
        // view_item
        viewItem: (data) => {
            const newData = formatData([data])

            rga4.event('view_item', {
                event_label: 'Ver Plato',
                event_category: 'ecommerce',
                currency: "CLP",
                value: newData.value,
                items: newData.items
            })
        },
        // view_item_list
        viewItemList: (data) => {
            const newData = formatData(data.products)

            rga4.event('view_item_list', {
                event_label: 'Ver lista de productos',
                event_category: 'ecommerce',
                item_list_id: data.category_id,
                item_list_name: data.category_name,
                items: newData.items
            })
        },
        // view_promotion
        viewPromotion: (data) => {
            const newData = formatData([data])

            rga4.event('view_promotion', {
                event_label: 'Ver Promocion',
                event_category: 'ecommerce',
                currency: "CLP",
                value: newData.value,
                items: newData.items
            })
        },
        // view_cart
        viewCart: (data) => {
            const newData = formatData(data)

            rga4.event('view_cart', {
                event_label: 'Ver Carrito',
                event_category: 'ecommerce',
                currency: "CLP",
                value: newData.value,
                items: newData.items
            })
        },
        // sign_up
        signUp: (data) => {
            // evento con parametros personalizados
            rga4.event('sign_up', {
                event_label: 'Registro de nuevo usuario',
                event_category: 'ecommerce',
                id: data.id,
                full_name: data.name + data.last_name,
                email: data.email
            })
        },
        // login
        login: (data) => {
            // evento con parametros personalizados
            rga4.event('login', {
                event_label: 'Inicio de sesion',
                event_category: 'ecommerce',
                id: data.id,
                full_name: data.name + data.last_name,
                email: data.email
            })
        },
        // search
        search: (data) => {
            rga4.event('search', {
                event_label: 'Busqueda',
                event_category: 'ecommerce',
                search_term: data
            })
        },
        // select_promotion
        selectPromotion: (data) => {
            const newData = formatData([data])

            rga4.event('select_promotion', {
                event_label: 'Seleccion de un recomendado',
                event_category: 'ecommerce',
                promotion_name: 'Recomendados',
                items: newData.items
            })
        }
    }

    return actions
}

export default useGoogleAnalytics