import React, { useEffect, useState } from 'react'

import Donations from '@components/Donations'
import Tips from '@components/Tips'
import { useCart } from '@hooks/useCart'
import { useCheckout } from '@hooks/useCheckout'
import { useGiftcard } from '@hooks/useGiftcard'
import miles from '@utils/miles'

import _each from 'lodash/each'
import _get from 'lodash/get'
import _size from 'lodash/size'

import './styles.scss'

export default function Summary() {
  const [cart] = useCart()
  const [gitftcard] = useGiftcard()
  const [checkout, setCheckout] = useCheckout()
  const {
    delivery_fee: deliveryFee = 0,
    tip_amount: tipAmount = 0,
    donation_amount: donationAmount = 0,
    coupon_name: couponName = '',
    coupon_discount: couponDiscount = 0
  } = checkout

  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const totalInject = total + deliveryFee + tipAmount + donationAmount - couponDiscount

  useEffect(() => {
    const getItemPrice = (item) => {
      return !_get(item, 'discount', 0) ? _get(item, 'price', 0) : _get(item, 'discount', 0)
    }

    let t = 0
    let s = 0
    let d = 0

    if (_size(cart) > 0) {
      _each(cart, (item) => {
        t = t + (getItemPrice(item) + item.optionalsPrice || 0) * item.quantity
        s = s + (item.price + item.optionalsPrice || 0) * item.quantity
        d = d + _get(item, 'discount', 0)
      })
    }

    if (_size(gitftcard) > 0) {
      _each(gitftcard, (item) => {
        t = t + getItemPrice(item) * item.quantity
        s = s + item.price * item.quantity
        d = d + _get(item, 'discount', 0)
      })
    }

    setTotal(t)
    setSubTotal(s)
    setDiscount(s - t)
  }, [cart, gitftcard])

  useEffect(() => {
    setCheckout({ field: 'total', value: totalInject })
    setCheckout({ field: 'sub_total', value: subTotal })

    // eslint-disable-next-line
  }, [totalInject])

  return (
    <div className="summary">
      <div className="summary__item">
        <span>Subtotal</span>
        <span>${miles(subTotal)}</span>
      </div>
      {_size(cart) > 0 && !!deliveryFee && (
        <div className="summary__item">
          <span>Despacho</span>
          <span>${miles(deliveryFee)}</span>
        </div>
      )}
      {discount > 0 && (
        <div className="summary__item">
          <span>Descuento</span>
          <span>- ${miles(discount + couponDiscount)}</span>
        </div>
      )}
      {''}
      {!!couponName && (
        <div className="summary__item">
          <span>{couponName}</span>
        </div>
      )}
      <Tips />
      <Donations />
      <div className="summary__item summary__item--highlight">
        <span>Total</span>
        <span>
          $
          {miles(
            total +
              (_size(gitftcard) > 0 && _size(cart) === 0 ? 0 : deliveryFee) +
              tipAmount +
              donationAmount -
              couponDiscount
          )}
        </span>
      </div>
    </div>
  )
}
