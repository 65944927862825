import React, { useContext, useState, useEffect } from 'react'

import ProductSidebar from '@components/ProductSidebar'
import SearchSidebar from '@components/SearchSidebar'
import Tabs from '@components/Tabs'
import { useRouter } from '@hooks/useRouter'
import categories from '@pages/Admin/Layout/categories'
import SidebarMenu from '@pages/Admin/Layout/SidebarMenu'
import useAxios from 'axios-hooks'
import PropTypes from 'prop-types'

import _filter from 'lodash/filter'
import _flow from 'lodash/flow'
import _partialRight from 'lodash/partialRight'
import _property from 'lodash/property'
import _some from 'lodash/some'

import ProductContextProvider, { ProductsContext } from '../ProductContext'

const Layout = ({ children, ...rest}) => {
  return (
    <ProductContextProvider>
      <LayoutWithoutProviders {...rest} >
        {children}
      </LayoutWithoutProviders>
    </ProductContextProvider>
  )
}

const LayoutWithoutProviders = ({ navs, excludedPaths, children }) => {
  const { location } = useRouter()
  const isExcluded = excludedPaths.indexOf(location.pathname) !== -1
  const [{ data: getCategories }] = useAxios({ url: '/dashboards/selects?type=categories' })
  const { products: allProducts = [] } = useContext(ProductsContext)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [query, setQuery] = useState('')
  const [products, setProducts] = useState([])

  const newProductNavs = [{ name: 'Información', path: '/admin/products/information/' }]

  useEffect(() => {
    setProducts(allProducts)
  }, [allProducts])

  useEffect(() => {
    if (selectedCategory) {
      const filteredProducts = _filter(
        allProducts,
        _flow(_property('categories'), _partialRight(_some, { id: selectedCategory }))
      )
      setProducts(filteredProducts)
    } else {
      setProducts(allProducts)
    }
  }, [selectedCategory, allProducts])

  useEffect(() => {
    const filteredProducts = allProducts.filter((product) =>
      product?.name?.toLowerCase().includes(query.toLowerCase())
    )
    setProducts(filteredProducts)
    // eslint-disable-next-line
  }, [query])

  const productsCategories = getCategories?.map((category) => ({
    value: category.id,
    label: category.name
  }))

  return (
    <main>
      <SidebarMenu categories={categories} />
      <div className="main-wrapper">
        <div className="grid-layout">
          <SearchSidebar
            hasSelect
            createLink="/admin/products/information/"
            options={productsCategories}
            query={query}
            setQuery={setQuery}
            setSelectedCategory={setSelectedCategory}
          >
            <ProductSidebar products={products} />
          </SearchSidebar>
          <div className="grid-layout__wrapper">
            {window.location.pathname === '/admin/products/information/' ? (
              <Tabs tabs={newProductNavs} />
            ) : (
              !isExcluded && navs.length > 0 && <Tabs tabs={navs} />
            )}
            {children}
          </div>
        </div>
      </div>
    </main>
  )
}

Layout.propTypes = {
  navs: PropTypes.array,
  excludedPaths: PropTypes.array,
  children: PropTypes.any
}

export default Layout
