import React, { useEffect, useState } from 'react'

import { useCart } from '@hooks/useCart'
import { useCheckout } from '@hooks/useCheckout'
import { useGiftcard } from '@hooks/useGiftcard'
import miles from '@utils/miles'

import _get from 'lodash/get'
import _size from 'lodash/size'

import './styles.scss'

export default function Tips() {
  const [checkout, setCheckout] = useCheckout()
  const [cart] = useCart()
  const [giftcard] = useGiftcard()

  const { tip_amount: initialTip = 0, payment_type: paymentType = '' } = checkout
  const [tip, setTip] = useState(parseInt(initialTip))
  const typeOrder = _get(checkout, 'type_order')
  const tipAmmounts = [0, 500, 1000, 2000, 5000]

  useEffect(() => {
    setCheckout({ field: 'tip_amount', value: tip })
    // eslint-disable-next-line
  }, [tip])

  useEffect(() => {
    if (typeOrder === 'takeaway') {
      setTip(0)
    }
    if (paymentType !== 'webpayplus') {
      setTip(0)
    }
    if (paymentType !== 'oneclick') {
      setTip(0)
    }
  }, [typeOrder, paymentType])

  if (_size(cart) === 0 && _size(giftcard) > 0) {
    return null
  }

  return typeOrder !== 'takeaway' && (paymentType === 'webpayplus' || paymentType === 'sodexo' || paymentType === 'oneclick' || paymentType === 'edenred' || paymentType === 'zeleri-transfer') ? (
    <div className="tips">
      <span>Propina para el repartidor</span>
      <div className="tips__amount">
        {tipAmmounts.map((tipValue, index) => {
          const className = parseInt(tipValue) === parseInt(tip) ? 'tip active' : 'tip'
          return (
            <span className={className} key={index} onClick={() => setTip(tipValue)}>
              ${miles(tipValue)}
            </span>
          )
        })}
      </div>
    </div>
  ) : null
}
