import React, { useEffect } from 'react'

// import Intercom from 'react-intercom'
// import CustomerChat from '@components/CustomerChat'
// import useAuth from '@hooks/useAuth'

import Tabs from '@components/Tabs'
import { useConfig } from '@hooks/useConfig'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import _find from 'lodash/find'
import _get from 'lodash/get'

import SidebarNavbar from '../SidebarNavbar'

import Footer from './Footer'
import GlobalStyle from './GlobalStyle'
import Header from './Header'
import loadCss from './loadCss'

Layout.propTypes = {
  navs: PropTypes.array,
  settings: PropTypes.bool,
  url: PropTypes.string,
  children: PropTypes.any
}

export default function Layout({ children, settings = false, navs = [], url }) {
  // const [{ profile }] = useAuth()
  // const { integrations, sections, design, pages } = useConfig()
  const { sections, design, pages } = useConfig()

  const page = _find(pages, { path: url })
  const useFooter = !page ? true : _get(page, 'use_footer', false)
  const useNavbar = !page ? true : _get(page, 'use_navbar', false)

  // const intercomId = _get(integrations, '0.intercom_id', '')
  // const facebookAppId = _get(integrations, '0.facebook_chat_app_id', '')
  // const facebookPageId = _get(integrations, '0.facebook_chat_page_id', '')

  const navbar = _find(sections, { type: 'navbar' })
  const footer = _find(sections, { type: 'footer' })

  const theme = {
    title: _get(design, 'fonts_title'),
    subtitle: _get(design, 'fonts_subtitle'),
    body: _get(design, 'fonts_body')
  }

  const textColor = _get(design, 'text_color')
  const bgColor = _get(design, 'background_color')

  const btnColor = _get(design, 'button_color')
  const btnTextColor = _get(design, 'button_text_color')

  useEffect(() => {
    loadCss(_get(design, 'fonts_title'))
    loadCss(_get(design, 'fonts_subtitle'))
    loadCss(_get(design, 'fonts_body'))
    // eslint-disable-next-line
  }, [])

  return settings ? (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <div className="wrapper">
        <div className="container">
          <h3 className="wrapper__title">Mi Cuenta</h3>
        </div>
        {navs && <Tabs tabs={navs} />}
        {children}
        <style>
          {`
        :root {
          --clr-text: ${textColor};
          --clr-bg: ${bgColor};
          --clr-btn: ${btnColor};
          --clr-text-btn: ${btnTextColor};
          }
        `}
        </style>
      </div>
      <Footer />
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SidebarNavbar navbar={navbar} useNavbar={useNavbar} />
      <div className="site-container">
        <div className="content">{children}</div>
        <style>
          {`
          :root {
          --clr-text: ${textColor};
          --clr-bg: ${bgColor};
          --clr-btn: ${btnColor};
          --clr-text-btn: ${btnTextColor};
          }
        `}
        </style>
      </div>
      {/* <Intercom appID={intercomId} email={profile.email} name={profile.name} user_id={profile.id} /> */}
      {/* <CustomerChat appId={facebookAppId} pageId={facebookPageId} /> */}
      {footer && useFooter ? <Footer /> : null}
    </ThemeProvider>
  )
}
